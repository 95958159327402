import React from 'react'
import * as THREE from 'three'

import { ROAD_LENGTH, ROAD_OFFSET } from '../../utils/constants'

export default function Road() {
	const roadGeometry = new THREE.PlaneBufferGeometry(12, ROAD_LENGTH, 1, 1)
	roadGeometry.translate(0, ROAD_OFFSET, 0.1)
	roadGeometry.rotateX(-Math.PI * 0.5)

	const roadMaterial = new THREE.MeshBasicMaterial({ color: 0x03353b, transparent: true, opacity: 0.7 })

	return (
		<mesh geometry={roadGeometry} material={roadMaterial} />
	)
}
