import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import * as THREE from 'three'
import { easings, animated, useSpring } from '@react-spring/three'

import { ANIMATION_LENGTH, ROAD_LENGTH } from '../../utils/constants'

const SCALE_FACTOR = 200

const ROUGHNESS = 0.5
const DISPLACEMENT_SCALE = 0.4 * SCALE_FACTOR

const TEXTURE_LOADER = new THREE.TextureLoader()

const Walls = forwardRef(function Walls(props, ref) {
	const gridTexture = useRef(TEXTURE_LOADER.load('/textures/grid.png')).current
	const heightTexture = useRef(TEXTURE_LOADER.load('/textures/displacement.png')).current

	const [{ displacementScale }, setDisplacementScale] = useSpring(() => ({ displacementScale: DISPLACEMENT_SCALE }))

	function showWalls() {
		setDisplacementScale.set({ displacementScale: DISPLACEMENT_SCALE / 2 })
		setDisplacementScale.start({ displacementScale: DISPLACEMENT_SCALE, config: { duration: ANIMATION_LENGTH, easing: easings.easeOutExpo } })
	}

	function hideWalls() {
		// setDisplacementScale.set({ displacementScale: 0 })
		// setDisplacementScale.start({ displacementScale: DISPLACEMENT_SCALE, config: { duration: ANIMATION_LENGTH, easing: easings.easeOutExpo } })
	}

	useImperativeHandle(ref, () => ({
		playIntroAnimation() {
			showWalls()
		},
		stopIntroAnimation() {
			showWalls()
		}
	}))

	// Create materials
	// const geometry = new THREE.PlaneGeometry(200, ROAD_LENGTH, 24, 24)
	// const material = new THREE.MeshStandardMaterial({ map: gridTexture, displacementMap: heightTexture, displacementScale: DISPLACEMENT_SCALE, roughness: ROUGHNESS })

	// const mesh = new THREE.Mesh(geometry, material)

	// mesh.rotation.x = -Math.PI * 0.5

	// const Y_POS = -0.1

	// mesh.position.y = Y_POS
	// mesh.position.z = -(ROAD_LENGTH / 2)

	// const spotLight1Ref = useRef<THREE.SpotLight>()
	// useEffect(() => {
	// 	spotLight1Ref.current.target.position.x = -0.25
	// 	spotLight1Ref.current.target.position.y = 0.25
	// 	spotLight1Ref.current.target.position.z = 0.25

	// }, [spotLight1Ref.current])

	// const spotLight2Ref = useRef<THREE.SpotLight>()
	// useEffect(() => {
	// 	spotLight2Ref.current.target.position.x = 0.25
	// 	spotLight2Ref.current.target.position.y = 0.25
	// 	spotLight2Ref.current.target.position.z = 0.25

	// }, [spotLight2Ref.current])

	// console.log('render')

	return (
		<>
			{/* <primitive object={mesh} dispose={null} /> */}
			<mesh
				position={[0, -0.1, -(ROAD_LENGTH / 2)]}
				rotation={[-(Math.PI * 0.5), 0, 0]}
			>
				<planeGeometry
					args={[200, ROAD_LENGTH, 24, 24]}
				/>
				<animated.meshStandardMaterial
					args={[{ map: gridTexture, displacementMap: heightTexture, roughness: ROUGHNESS }]}
					// map={gridTexture}
					// displacementMap={heightTexture}

					// roughness={ROUGHNESS}
					displacementScale={displacementScale}
				/>
			</mesh>

			<ambientLight color="#ffffff" intensity={10} />
			{/* <spotLight
				ref={spotLight1Ref}

				color={0xFF1E99}
				intensity={40}
				distance={25}
				angle={Math.PI * .1}
				penumbra={.25}

				position={[0.5, 0.75, 2.1]}
			/>
			<spotLight
				ref={spotLight2Ref}

				color={0xFF1E99}
				intensity={40}
				distance={25}
				angle={Math.PI * .1}
				penumbra={.25}

				position={[-0.5, 0.75, 2.1]}
			/> */}
		</>
	)
})

export default Walls
