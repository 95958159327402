import React, { CSSProperties } from 'react'
import { Button } from './Menu'

interface MenuModalProps {
	title: string
	children: React.ReactNode

	backLabel?: string

	onClose: () => void
}

export default function MenuModal({ title, children, backLabel, onClose }: MenuModalProps) {
	return (
		<div style={styles.modal}>
			<div style={styles.titleArea}>
				<h2 style={styles.title}>{title}</h2>
			</div>
			<div style={styles.contentArea}>
				{children}
				<Button onClick={onClose}>
					<p style={styles.buttonLabel}>{backLabel || 'Back To Menu'}</p>
				</Button>
			</div>
		</div>
	)
}

const styles: { [key: string]: CSSProperties } = {
	modal: {
		position: 'fixed',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		zIndex: 1
	},
	titleArea: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: '60px'
	},
	title: {
		position: 'absolute',
		width: '100vw',
		// flex: 1,
		margin: '0px',
		color: 'white',
		fontFamily: 'Pixel Splitter Bold',
		zIndex: '9',
		textShadow: '0 0 15px black',
		textAlign: 'center'
	},
	contentArea: {
		position: 'absolute',
		top: '60px',
		left: 0,
		width: 'calc(100% - (1em * 2))',
		height: '100%',
		overflowY: 'scroll',
		paddingBottom: '60px',
		backgroundColor: 'rgba(0, 0, 0, 0.8)',
		boxShadow: 'black 0px 0px 65px',
		borderTopLeftRadius: '15px',
		borderTopRightRadius: '15px',
		padding: '1em'
	},
	buttonLabel: {
		color: 'black',
		margin: 0,
		fontSize: '1.5em',
		textAlign: 'center',
		fontFamily: 'Pixel Splitter Bold'  
	}
}

