import React from 'react'

import * as THREE from 'three'

import createGridMaterial from '../../utils/materials'
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'

import { ROAD_LENGTH, ROAD_OFFSET } from '../../utils/constants'

export default function Sidewalk({ addShader }: { addShader: (shader: THREE.Shader) => void }) {
	const sidewalkTopLeftGeometry = new THREE.PlaneBufferGeometry(8, ROAD_LENGTH, 1, 1)
	sidewalkTopLeftGeometry.translate(-10, ROAD_OFFSET, 0.5)
	sidewalkTopLeftGeometry.rotateX(-Math.PI * 0.5)

	const sidewalkSideLeftGeometry = new THREE.PlaneBufferGeometry(0.5, ROAD_LENGTH, 1, 1)
	sidewalkSideLeftGeometry.translate(0.06, ROAD_OFFSET, 6)
	sidewalkSideLeftGeometry.rotateX(-Math.PI * 0.5)
	sidewalkSideLeftGeometry.rotateZ(Math.PI * 0.49)

	const sidewalkTopRightGeometry = new THREE.PlaneBufferGeometry(8, ROAD_LENGTH, 1, 1)
	sidewalkTopRightGeometry.translate(10, ROAD_OFFSET, 0.5)
	sidewalkTopRightGeometry.rotateX(-Math.PI * 0.5)

	const sidewalkSideRightGeometry = new THREE.PlaneBufferGeometry(0.5, ROAD_LENGTH, 1, 1)
	sidewalkSideRightGeometry.translate(0.44, ROAD_OFFSET, -6)
	sidewalkSideRightGeometry.rotateX(-Math.PI * 0.5)
	sidewalkSideRightGeometry.rotateZ(Math.PI * 0.49)

	// Merge all sidewalk geometries
	const sidewalkConception = []
	sidewalkConception.push(sidewalkTopLeftGeometry)
	sidewalkConception.push(sidewalkSideLeftGeometry)
	sidewalkConception.push(sidewalkTopRightGeometry)
	sidewalkConception.push(sidewalkSideRightGeometry) // Push all geometries into sidewalkConception array

	const sidewalkGeometry = BufferGeometryUtils.mergeBufferGeometries(sidewalkConception, false) // Merge all geometries within sidewalkConception array

	// Sidewalk material (grid)
	const sidewalkMaterial = createGridMaterial({ color: 0x1be9ff, side: THREE.DoubleSide, transparent: true, opacity: 0.8 })
	sidewalkMaterial.onBeforeCompile = shader => addShader(shader)

	return (
		<mesh geometry={sidewalkGeometry} material={sidewalkMaterial} />
	)
}
