import React from 'react'
import Swoosh from './Swoosh'

interface LoadingScreen {
  show: boolean
  message?: string
}

export default function LoadingScreen({ show, message }: LoadingScreen) {
	return (
		<div id="loading-container" className={`screen ${!show ? 'hidden' : ''}`}>
			<Swoosh />
			{message && <p id="reason">{message}</p>}
		</div>
	)
}
