import React, { useState } from 'react'

import { useSpring, animated, easings, SpringConfig } from '@react-spring/three'

import useStore from '../../store'
import { useListeners } from '../../utils/hooks'

const SPHERE_SIZE = 0.4
const SPHERE_SPACING = .9

const BOX_WIDTH = (SPHERE_SIZE * 3) + (SPHERE_SPACING * 2)

const SPHERE_ARGS: [
  radius?: number,
  widthSegments?: number,
  heightSegments?: number,
  phiStart?: number,
  phiLength?: number,
  thetaStart?: number,
  thetaLength?: number
] = [SPHERE_SIZE, 10, 10]

export default function TrafficLights() {
	const config: SpringConfig = { duration: 1000, easing: easings.easeOutQuad }

	const [boxArgs, setBoxArgs] = useSpring(() => ({ position: [0, 0, 0], config }))
	const [redArgs, setRedArgs] = useSpring(() => ({ scale: 0, position: [0, 5, 0], config }))
	const [amberArgs, setAmberArgs] = useSpring(() => ({ scale: 0, position: [0, 5, 0], config }))
	const [greenArgs, setGreenArgs] = useSpring(() => ({ scale: 0, position: [0, 5, 0], config }))
	const [materialArgs, setMaterialArgs] = useSpring(() => ({ opacity: 0, config }))

	function reset() {
		setBoxArgs.set({ position: [0, 0, 0] })
		setRedArgs.set({ scale: 0, position: [0, 5, 0] })
		setAmberArgs.set({ scale: 0, position: [0, 5, 0] })
		setGreenArgs.set({ scale: 0, position: [0, 5, 0] })
		setMaterialArgs.set({ opacity: 1 })
	}

	const gameServices = useStore(store => store.gameServices)
	useListeners({
		MATCH_UPDATE: ({ state }) => {
			switch(state) {
			case 'READY':
				setMaterialArgs.set({ opacity: 1 })

				break
			}
		},
		MATCH_LEFT: () => {
			setMaterialArgs.set({ opacity: 0 })
		},
		ROUND_READY: () => {
			reset()
		},
		ROUND_START: () => {
			setBoxArgs.set({ position: [0, 0, 0] })
			setBoxArgs.start({ position: [0, 0, 5] })
		},
		ROUND_START_COUNTDOWN: ({ seconds_left: secondsLeft }) => {
			if(secondsLeft === 3) {
				reset()
				console.log('RESET!')
			}

			if(secondsLeft === 2) {
				setRedArgs.set({ scale: 0, position: [0, 5, 0] })
				setRedArgs.start({ scale: 1, position: [-SPHERE_SPACING, 5, 0.25] })
			}

			if(secondsLeft === 1) {
				setAmberArgs.set({ scale: 0, position: [0, 5, 0] })
				setAmberArgs.start({ scale: 1, position: [0, 5, 0.25] })
			}
  
			if(secondsLeft === 0) {
				setGreenArgs.set({ scale: 0, position: [0, 5, 0] })
				setGreenArgs.start({ scale: 1, position: [SPHERE_SPACING, 5, 0.25] })
			}
		}
	}, gameServices)

	return (
		<>
			<animated.group {...boxArgs as unknown as any}>
				<mesh position={[0, 5, 0]}>
					<boxBufferGeometry args={[BOX_WIDTH, 1, 1]} />
					<animated.meshNormalMaterial wireframe={true} transparent={true} {...materialArgs as unknown as any} />
				</mesh>
				<animated.mesh {...redArgs as unknown as any}>
					<sphereBufferGeometry args={SPHERE_ARGS} />
					<animated.meshNormalMaterial wireframe={true} transparent={true} {...materialArgs as unknown as any} />
				</animated.mesh>
				<animated.mesh {...amberArgs as unknown as any}>
					<sphereBufferGeometry args={SPHERE_ARGS} />
					<animated.meshNormalMaterial wireframe={true} transparent={true} {...materialArgs as unknown as any} />
				</animated.mesh>
				<animated.mesh {...greenArgs as unknown as any}>
					<sphereBufferGeometry args={SPHERE_ARGS} />
					<animated.meshNormalMaterial wireframe={true} transparent={true} {...materialArgs as unknown as any} />
				</animated.mesh>
			</animated.group>
		</>
	)
}
