import React from 'react'
import * as THREE from 'three'

import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'

import { ROAD_LENGTH, ROAD_OFFSET } from '../../utils/constants'

export default function RoadLines() {
	const roadLineLeftGeometry = new THREE.PlaneBufferGeometry(0.35, ROAD_LENGTH, 1, 1)
	roadLineLeftGeometry.translate(-5.2, ROAD_OFFSET, 0.2)
	roadLineLeftGeometry.rotateX(-Math.PI * 0.5)

	const roadLineRightGeometry = new THREE.PlaneBufferGeometry(0.35, ROAD_LENGTH, 1, 1)
	roadLineRightGeometry.translate(5.2, ROAD_OFFSET, 0.2)
	roadLineRightGeometry.rotateX(-Math.PI * 0.5)

	const roadLineCenterLeftGeometry = new THREE.PlaneBufferGeometry(0.15, ROAD_LENGTH, 1, 1)
	roadLineCenterLeftGeometry.translate(-1.8, ROAD_OFFSET, 0.2)
	roadLineCenterLeftGeometry.rotateX(-Math.PI * 0.5)

	const roadLineCenterRightGeometry = new THREE.PlaneBufferGeometry(0.15, ROAD_LENGTH, 1, 1)
	roadLineCenterRightGeometry.translate(1.8, ROAD_OFFSET, 0.2)
	roadLineCenterRightGeometry.rotateX(-Math.PI * 0.5)

	// Merge all road lines geometries
	const roadLinesConception = []
	roadLinesConception.push(roadLineLeftGeometry)
	roadLinesConception.push(roadLineRightGeometry)
	roadLinesConception.push(roadLineCenterLeftGeometry)
	roadLinesConception.push(roadLineCenterRightGeometry) // Push all geometries into roadLinesConception array

	const roadLineMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0.3 })
	const roadLinesGeometry = BufferGeometryUtils.mergeBufferGeometries(roadLinesConception, false) // Merge all geometries within sidewalkConception array

	return (
		<mesh geometry={roadLinesGeometry} material={roadLineMaterial} />
	)
}
