import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { useSpring, SpringConfig, animated, easings } from '@react-spring/three'

import { AnimatedText } from '../../../utils/helpers'
import { ANIMATE_TO_TITLE, TITLE_ANIMATION_LENGTH } from '../../../utils/constants'
import { useListeners } from '../../../utils/hooks'
import useStore from '../../../store'

const DEFAULT_GAME_TITLE_POSITION: [x: number, y: number, z: number] = [0, 5, ANIMATE_TO_TITLE]
const DEFAULT_GAME_TITLE_ROTATION: [x: number, y: number, z: number] = [0, 0, 0]

const GameTitleLabel = forwardRef(function GameTitle(props, ref) {
	const me = useStore(state => state.me)
	const leaderboard = useStore(state => state.leaderboard)

	const config: SpringConfig = { duration: TITLE_ANIMATION_LENGTH, easing: easings.easeOutQuad }
	const [{ opacity }, setOpacity] = useSpring(() => ({ opacity: 1, config }))
	const [{ position }, setPosition] = useSpring(() => ({ position: DEFAULT_GAME_TITLE_POSITION, config }))
	const [{ rotation }, setRotation] = useSpring(() => ({ rotation: DEFAULT_GAME_TITLE_ROTATION, config }))

	function playRoundIntroAnimation() {
		setOpacity.set({ opacity: 1 })
		setOpacity.start({ opacity: 0, config: { ...config, duration: 5000 / 5 } })
	}

	function stopRoundIntroAnimation() {
		setOpacity.set({ opacity: 1 })
	}

	useListeners({
		MATCH_UPDATE: ({ state }) => {
			switch(state) {
			case 'READY':
				playRoundIntroAnimation()

				break
			case 'WAITING_FOR_PLAYERS':
				stopRoundIntroAnimation()

				break
			}
		},
		MATCH_LEFT: () => {
			stopRoundIntroAnimation()
		}
	})

	useImperativeHandle(ref, () => ({
		playIntroAnimation() {
			// Set starting point
			setOpacity.set({ opacity: 0 })
			setPosition.set({ position: [0, -5, 7 * 2] })
			setRotation.set({ rotation: [0, 0, 0] })

			setOpacity.start({ opacity: 1 })
			setPosition.start({ position: DEFAULT_GAME_TITLE_POSITION })
			setRotation.start({ rotation: DEFAULT_GAME_TITLE_ROTATION })
		},
		stopIntroAnimation() {
			setOpacity.stop()
			setPosition.stop()
			setRotation.stop()

			setOpacity.set({ opacity: 1 })
			setPosition.set({ position: DEFAULT_GAME_TITLE_POSITION })
			setRotation.set({ rotation: DEFAULT_GAME_TITLE_ROTATION })
		}
	}))

	const myScore = leaderboard?.cumulative?.personal?.find(entry => entry.me)?.score

	return (
		<animated.group
			position={position as unknown as any}
			rotation={rotation as unknown as any}
		>
			<AnimatedText
				font="/fonts/PixelSplitter-Bold.woff"

				color="aqua" // default
				anchorX="center" // default
				anchorY="middle" // default
				fontSize={0.6}
				fillOpacity={opacity}
			>
				GEM HEIST
			</AnimatedText>
			{me && (
				<AnimatedText
					font="/fonts/PixelSplitter-Bold.woff"

					color="aqua" // default
					anchorX="center" // default
					anchorY="middle" // default
					fontSize={0.25}
					fillOpacity={opacity}

					position={[0, -0.5, 0]}
				>
					{me.name}{myScore ? ` - ${leaderboard.cumulative.personal.find(entry => entry.me).score.toLocaleString()} gems` : ''} 
				</AnimatedText>
			)}
		</animated.group>
	)
})

export default GameTitleLabel
