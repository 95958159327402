import React, { CSSProperties, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { animated, useSpring, easings } from 'react-spring'

import useStore from '../../store'
import { LEADERBOARD_ANIMATION_CONFIG } from '../../utils/constants'
import { useListeners, useLocalStorage } from '../../utils/hooks'
import Swoosh from '../Swoosh'
import HowToPlay from './Content/HowToPlay'
import Rules from './Content/Rules'
import MenuModal from './MenuModal'

interface ButtonProps {
	onClick: () => void

	className?: string
	style?: CSSProperties
	additionalStyles?: CSSProperties

	loading?: boolean
	disabled?: boolean
	disableAnimation?: boolean
	
	children
}

export function Button({ style, additionalStyles, onClick, loading, disabled, disableAnimation, children, className }: ButtonProps) {
	const [active, setActive] = useState(false)
	// const activeStyles = useSpring({ to: { transform: active ? 'scale(0.95)' : 'scale(1)' }, config: { duration: 100 } })

	// const { opacity: contentOpacity } = useSpring({ opacity: loading ? 0 : 1, config: { duration: 0 } })
	// const { opacity: loadingOpacity } = useSpring({ opacity: loading ? 1 : 0, config: { duration: 0 } })
	const contentOpacity = loading ? 0 : 1
	const loadingOpacity = loading ? 1 : 0

	function onPointerUp() {
		if(disabled || disableAnimation)
			return

		setActive(false)
	}

	function onPointerDown() {
		if(disabled || disableAnimation)
			return

		setActive(true)
	}

	return (
		<div style={{...(style || styles.button), ...additionalStyles, ...({transform: active ? 'scale(0.95)' : 'scale(1)' }) }} {...{onPointerUp, onPointerDown}} {...(!disabled ? { onClick } : {})} className={className}>
			<div style={{ opacity: contentOpacity }}>
				{children}
			</div>
			{!style && (
				<div style={{ ...styles.buttonLoadingChild, opacity: loadingOpacity }}>
					<p>loading...</p>
				</div>
			)}
		</div>
	)
}

interface MenuProps {
	showLeaderboard: () => void
	hideLeaderboard: () => void
}

export default forwardRef(function Menu({ showLeaderboard, hideLeaderboard }: MenuProps, ref) {
	// State
	const me = useStore(state => state.me)
	const entries = useStore(store => store.entries)
	const leaderboard = useStore(store => store.leaderboard)
	const gameServices = useStore(store => store.gameServices)
	const connectedPlayerCount = useStore(store => store.connectedPlayerCount)

	const [loading, setLoading] = useState(false)
	
	const [visible, setVisible] = useState(false)
	const [matchmaking, setMatchmaking] = useState(false)

	const [rulesExpanded, setRulesExpanded] = useState(false)
	const [howToPlayExpanded, setHowToPlayExpanded] = useState(false)
	const [leaderboardExpanded, setLeaderboardExpanded] = useState(false)

	const freeEntriesRemaining = useStore(state => state.freeEntriesRemaining)
	const [selectedLeaderboard, setSelectedLeaderboard] = useState('global')

	const matchmakingTimeout = useRef(null)

	useEffect(() => {
		if(localStorage.getItem('has_seen_intro_animation') === '1')
			setVisible(true)
	}, [])

	// ENTER GAME
	useListeners({
		ENTRIES_REDEEMED: () => setLoading(false),
		ENTRIES_PURCHASED: ({ entry_ids }) => gameServices.emit('SEND_MESSAGE', { data: { entry_id: entry_ids[0] }, type: 'REDEEM_ENTRY' }),

		MATCH_UPDATE: ({ state }) => {
			switch(state) {
			case 'WAITING_FOR_PLAYERS':
				setVisible(true)
				setMatchmaking(true)
				
				if(matchmakingTimeout.current) {
					clearInterval(matchmakingTimeout.current)
					matchmakingTimeout.current = null
				}

				break
			case 'READY':
				setVisible(false)
				matchmakingTimeout.current = setTimeout(() => setMatchmaking(false), 2000)

				break
			}
		},
		MATCH_LEFT: () => {
			setVisible(true)
			setMatchmaking(false)
		}
	}, gameServices)

	function purchaseEntries(amount: number) {
		gameServices.emit('SEND_MESSAGE', { data: { amount }, type: 'PURCHASE_ENTRIES' })
	}

	function joinMatch() {
		if(entries.length === 0)
			return

		gameServices.emit('SEND_MESSAGE', { data: { entry_id: entries[0] }, type: 'REDEEM_ENTRY' })
	}

	function leaveMatch() {
		gameServices.emit('SEND_MESSAGE', { data: {}, type: 'LEAVE_MATCH' })
	}

	// UI
	useImperativeHandle(ref, () => ({
		showMenu() {
			setVisible(true)
		},
		hideMenu() {
			setVisible(false)
		}
	}))

	// LEADERBOARD STUFF
	function onClickShowLeaderboard() {
		showLeaderboard()
		setLeaderboardExpanded(true)
	}

	function onClickHideLeaderboard() {
		hideLeaderboard()
		setLeaderboardExpanded(false)
		setTimeout(() => setSelectedLeaderboard('global'), 100)
	}

	function onClickShowHowToPlay() {
		showLeaderboard()
		setHowToPlayExpanded(true)
	}

	function onClickHideHowToPlay() {
		hideLeaderboard()
		setHowToPlayExpanded(false)
	}

	function onClickShowRules() {
		showLeaderboard()
		setRulesExpanded(true)
	}

	function onClickHideRules() {
		hideLeaderboard()
		setRulesExpanded(false)
	}

	return (
		<>
			{rulesExpanded && (
				<MenuModal title='Rules' onClose={onClickHideRules}>
					<Rules />
				</MenuModal>
			)}
			{howToPlayExpanded && (
				<MenuModal title='How To Play' onClose={onClickHideHowToPlay}>
					<HowToPlay />
				</MenuModal>
			)}
			<div id={'menu'} className={[
				leaderboardExpanded && 'leaderboard-expanded',
				(visible && !(rulesExpanded || howToPlayExpanded)) && 'visible',
				matchmaking && 'matchmaking'
			].filter(e=>e).join(' ')} style={{...styles.menu}}>
				<div className={'prizeArea'} style={{...styles.prizeArea, top: '-260px'}}>
					<div style={{...styles.prize}}>
						<img style={{...styles.prizeImg, height: '66px' }} src='/iphone-13-pro-max.png'/>
						<div style={{...styles.prizeLabelWrapper}}>
							<p style={{...styles.prizeLabel, ...styles.prizeTopLabel }}>Grand Prize</p>
							<p style={{...styles.prizeLabel, ...styles.prizeNameLabel }}>iPhone 13<br/>Pro Max</p>
							{/* <p style={{...styles.prizeLabel, ...styles.prizeSubLabel }}>Any color</p> */}
						</div>
					</div>
					<div style={styles.prize}>
						<img style={{...styles.prizeImg, marginBottom: '10px', marginTop: '4px'}} src='/beanie-2.png'/>
						<div style={{...styles.prizeLabelWrapper}}>
							<p style={{...styles.prizeLabel, ...styles.prizeTopLabel, marginTop: 0, marginBottom: '4px' }}>Reach<br/>100k+ Gems</p>
							<p style={{...styles.prizeLabel, ...styles.prizeNameLabel, fontSize: 15 }}>Exclusive<br/>Extra Beanie</p>
						</div>
					</div>
				</div>
				<div className='menuArea' style={{...styles.menuArea, ...styles.playButtonArea, /*...menuStyles*/}}>
					<div>
						<Button {...{loading}} onClick={() => entries.length === 0 ? purchaseEntries(1) : joinMatch()}>
							{(entries.length > 0 || freeEntriesRemaining > 0) ? (
								<p style={styles.buttonLabel}>Play</p>
							) : (
								<>
									<p style={styles.buttonLabel}>Purchase Entry</p>
									<p style={styles.buttonSubLabel}>(900 pts)</p>
								</>
							)}
						</Button>
						{/* {entries.length > 0 && (
							<Button onClick={() => gameServices.emit('SEND_MESSAGE', { data: { entry_id: entries[0] }, type: 'REFUND_ENTRY' })}>
								refund entry ({entries[0]})
							</Button>
						)} */}

					</div>
					<div style={{...styles.prize, ...styles.labelArea, marginTop: '8px'}}>	
						<span>
							<span style={{ opacity: 0.75 }}>
							You have{' '}
							</span>
							{me.point_balance?.toLocaleString()}
							{' '}pts
						</span>
					</div>
				</div>
				<div className={'matchmakingArea'} style={{...styles.menuArea, ...styles.matchmakingArea, /*...matchmakingStyles*/}}>
					<div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
						<h1 style={styles.matchmakingTitle}>MATCHMAKING</h1>
						<Swoosh color='white' style={{ marginBottom: '0.1em' }} />
					</div>
					<div style={{ padding: '1em', paddingTop: '0' }}>
						<Button onClick={leaveMatch}>
							<p style={{...styles.buttonLabel, fontSize: '20px'}}>Leave Matchmaking</p>
						</Button>
					</div>
				</div>
				{leaderboard && (
					<>
						<Button
							style={{ position: 'fixed', left: 0, width: '100%', textAlign: 'center', /*...leaderboardStyles*/ } as unknown as any}
							className={'leaderboard'}
							onClick={leaderboardExpanded ? onClickHideLeaderboard : onClickShowLeaderboard}
							disabled={leaderboardExpanded}
							disableAnimation={true}
						>
							<div className='leaderboardHeader' style={styles.leaderboardHeader}>
								<h2 className={'leaderboardCTA'} style={{...styles.leaderboardCTALabel, /*...leaderboardCTAStyle*/}}>TAP TO SEE LEADERBOARD</h2>
								<h2 className={'leaderboardTitle'} style={{...styles.leaderboardTitleLabel, /*...leaderboardTitleStyle*/}}>LEADERBOARD</h2>
							</div>
							<div className={'leaderboardArea'} style={{...styles.menuArea, ...styles.leaderboardArea, /*...leaderboardAreaStyle*/}}>
								<table className='leaderboardTable' style={styles.leaderboardTable}>
									<thead>
										<tr>
											<th className={'leaderboardTableHeader'} style={styles.leaderboardTableHeader}>Rank</th>
											<th className={'leaderboardTableHeader'} style={styles.leaderboardTableHeader}>Name</th>
											<th className={'leaderboardTableHeader'} style={styles.leaderboardTableHeader}>Gems</th>
										</tr>
									</thead>
									<tbody>
										{leaderboard.cumulative[selectedLeaderboard === 'global' ? 'top' : 'personal'].map(item => (
											<tr key={item.position}>
												<td style={styles.leaderboardTableData}>{item.position.toLocaleString()}</td>
												<td style={styles.leaderboardTableData}>{item.name}{item.me ? ' (YOU)' : ''}</td>
												<td style={styles.leaderboardTableData}>{item.score.toLocaleString()}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</Button>
						<div style={{...styles.leaderboardPickerWrapper, pointerEvents: leaderboardExpanded ? 'all' : 'none' }}>
							<div className={'leaderboardPicker'} style={{...styles.leaderboardPicker, /*...leaderboardPickerSyle*/}}>
								<div onClick={onClickHideLeaderboard} style={{...styles.leaderboardOption, paddingLeft: '15px', paddingRight: '20px', padding: '0px', transform: 'translateY(-1px)'}}>
									<h1 style={styles.leaderboardOptionLabel}>&lt;</h1>
								</div>
								{/* <div onClick={() => setSelectedLeaderboard('daily')} style={{...styles.leaderboardOption, ...(selectedLeaderboard === 'daily' && styles.leaderboardOptionActive)}}>
									<h1 style={styles.leaderboardOptionLabel}>Today</h1>
								</div>
								<div onClick={() => setSelectedLeaderboard('total')} style={{...styles.leaderboardOption, ...(selectedLeaderboard === 'total' && styles.leaderboardOptionActive)}}>
									<h1 style={styles.leaderboardOptionLabel}>All-time</h1>
								</div> */}
								<div onClick={() => setSelectedLeaderboard('global')} style={{...styles.leaderboardOption, ...(selectedLeaderboard === 'global' && styles.leaderboardOptionActive)}}>
									<h1 style={styles.leaderboardOptionLabel}>Global</h1>
								</div>
								<div onClick={() => setSelectedLeaderboard('personal')} style={{...styles.leaderboardOption, ...(selectedLeaderboard === 'personal' && styles.leaderboardOptionActive)}}>
									<h1 style={styles.leaderboardOptionLabel}>Personal</h1>
								</div>
							</div>
						</div>
					</>
				)}
				{!leaderboardExpanded && (
					<div className={'headerArea'}>
						<div style={{ position: 'absolute', width: '100%', display: 'flex' }}>
							<div className='online' style={{...styles.prize, ...styles.labelArea, display: 'flex', width: 'auto', flexDirection: 'row', margin: '0 auto'}}>	

								<img style={{ width: 16, height: 16}} src='/user-red.png'></img>
								<span style={{ margin: '0 4px' }}>{connectedPlayerCount.toLocaleString()}</span>
								{/* <span style={{ opacity: 0.75 }}>
								online
								</span> */}

							</div>
						</div>

						<div className='buttons'>
							<Button style={{...styles.prize, ...styles.labelArea, boxShadow: 'none'}} onClick={onClickShowHowToPlay}>How to play</Button>
							<Button style={{...styles.prize, ...styles.labelArea, boxShadow: 'none'}} onClick={onClickShowRules}>Rules</Button>
						</div>
					</div>
				)}
			</div>
		</>
	)
})

const styles: { [key: string]: CSSProperties } = {
	menu: {
		position: 'fixed',
		left: 0,
		width: '100%',
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		// marginBottom: 'calc(15px + 30px)', // iphone notch
	},
	menuArea: {
		position: 'fixed',
		width: 'calc(100% - 15px)',
		bottom: '160px',
		borderRadius: 15,
		background: 'rgba(0,0,0,0.8)',
		// backdropFilter: 'saturate(180%) blur(5px)',
		// WebkitBackdropFilter: 'saturate(180%) blur(5px)',
		boxShadow: '0 0 65px black',
	},
	playButtonArea: {
		width: 'auto',
		bottom: 'calc(44vh)',
		boxShadow: 'none',
		background: 'none',
		filter: 'drop-shadow(rgba(0, 0, 0, 0.85) 0px 0px 40px)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	labelArea: {
		flex: 0,
		fontSize: 15,
		padding: '0.25rem 0.75rem',
		fontFamily: 'Pixel Splitter Bold',
		color: 'rgba(255,255,255,0.85)',
		background: 'rgba(0, 0, 0, 0.6)'
	},
	prizeArea: {
		position: 'fixed',
		width: 'calc(100% - 15px)',
		display: 'flex',
		// margin: '0 -5px'
	},
	prize: {
		borderRadius: 15,
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '0.8rem 0.25rem',
		margin: '0 6px',
		boxSizing: 'border-box',
		background: 'rgba(0,0,0,0.65)',
		// backdropFilter: 'saturate(180%) blur(5px)',
		// WebkitBackdropFilter: 'saturate(180%) blur(5px)',
		boxShadow: '0 0 65px black',
		// padding: '1em 1.25em',
	},
	prizeImg: {
		height: '56px',
		width: '120px',
		marginTop: '2px',
		marginBottom: '6px',
		objectFit: 'contain',
		WebkitFilter: 'drop-shadow(0 0 12px white)',
	},
	prizeLabelWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1
	},
	prizeLabel: {
		margin: 0,
		color: 'white',
		fontSize: '15px',
		fontFamily: 'Pixel Splitter Bold',
		textShadow: '0 0 25px black',
		textAlign: 'center'
	},
	prizeTopLabel: {
		fontSize: '11px',
		letterSpacing: '2px',
		opacity: 0.8,
		marginBottom: '2px',
		marginTop: '10px',
	},
	prizeNameLabel: {
		marginTop: 0,
		marginBottom: 0,
		fontSize: 17
	},
	prizeSubLabel: {
		fontSize: '10px',
		letterSpacing: '2px',
		opacity: 0.8,
		marginBottom: '2px',
		marginTop: '4px',
	},
	leaderboardArea: {
		position: 'fixed',
		height: '100vh',
		bottom: 'auto',
		// bottom: '-100px',
		width: '100%',
		marginBottom: '0px',
		borderBottomLeftRadius: '0px',
		borderBottomRightRadius: '0px'
	},
	button: {
		backgroundColor: 'aqua',
		padding: '12px 20px',
		borderRadius: 15,
		// transition: 'all 0.1s ease-in-out'
	},
	buttonLoadingChild: {
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		position: 'absolute',
		// background: 'red'
	},
	buttonLabel: {
		margin: 0,
		fontSize: '1.4em',
		textAlign: 'center',
		fontFamily: 'Pixel Splitter Bold'    
	},
	buttonSubLabel: {
		margin: 0,
		fontSize: '1.25em',
		textAlign: 'center',
		fontFamily: 'Pixel Splitter Bold'  
	},
	matchmakingArea: {
		bottom: '0px',
		zIndex: '1',
		marginBottom: 'calc(30px + 15px)'
	},
	matchmakingTitle: {
		margin: '0px',
		color: 'white',
		paddingTop: '0.75em',
		textAlign: 'center',
		fontSize: '25px',
		fontFamily: 'Pixel Splitter Bold'
	},
	leaderboardHeader: {
		// display: 'flex',
		height: '30px',
		// width: '200vw',
	},
	leaderboardCTALabel: {
		position: 'absolute',
		width: '100vw',
		// flex: 1,
		margin: '0px',
		color: 'white',
		fontSize: '17px',
		fontFamily: 'Pixel Splitter Bold',
		zIndex: '9',
		textShadow: '0 0 15px black'
	},
	leaderboardTitleLabel: {
		position: 'absolute',
		width: '100vw',
		// flex: 1,
		margin: '0px',
		color: 'white',
		fontFamily: 'Pixel Splitter Bold',
		zIndex: '9',
		textShadow: '0 0 15px black',
	},
	leaderboardPickerWrapper: {
		// position: 'fixed',
		left: '0',
		width: '100%',
		height: '20px',
		bottom: 'calc(20px + 30px)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	leaderboardPicker: {
		height: '100%',
		borderRadius: '60px',
		background: 'rgba(0,0,0,0.8)',
		// backdropFilter: 'saturate(180%) blur(5px)',
		boxShadow: 'black 0px 0px 65px',
		display: 'flex',
		padding: '10px'
	},
	leaderboardOption: {
		color: 'white',
		borderRadius: '60px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0px 10px',
		cursor: 'pointer'
	},
	leaderboardOptionActive: {
		color: 'black',
		backgroundColor: 'aqua',
	},
	leaderboardOptionLabel: {
		margin: 0,
		fontSize: '13px',
		// fontSize: '22.5px',
		fontFamily: 'Pixel Splitter Bold'
	},
	leaderboardTable: {
		color: 'white',
		fontFamily: 'Pixel Splitter Bold',
		columnFill: 'balance',
		tableLayout: 'auto',
		width: '100%',
		textAlign: 'left'
	},
	leaderboardTableHeader: {
		textAlign: 'center',
		fontSize: '16px',
		opacity: 0.7
	},
	leaderboardTableData: {
		textAlign: 'center'
	}
}
