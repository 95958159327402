import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'

import { useRouter } from 'next/router'

import EventEmitter from 'eventemitter3'

import useStore from '../store'
import { IS_PROD } from '../components/ExtraGameServices'

export function useToken() {
	// Get router
	const router = useRouter()
	
	// Get token from query params
	const token = router.query.token as string
	if(!IS_PROD)
		return token

	const supportsLocalStorage = typeof localStorage !== 'undefined'
	if(!supportsLocalStorage)
		return token

	// If token exists in query params
	if(token) {
		// Add to local storage
		localStorage.setItem('extra_token', token)

		// Remove from url
		const url = new URL(window.location.href)
		url.searchParams.delete('token')
		window.history.pushState({}, '', url)
	
		// Return
		return token
	}

	// Try fetch from local storage
	return localStorage.getItem('extra_token')
}

interface Listeners {
	[key: string]: (data: any) => void
}

export function useListeners(listeners: Listeners, gameServices?: EventEmitter<string | symbol, any>) {
	if(!gameServices)
		gameServices = useStore(state => state.gameServices)

	useEffect(() => {
		if(!gameServices)
			return () => {}

		const events = Object.keys(listeners)
		events.forEach(event => gameServices.addListener(event, listeners[event]))

		return () => {
			events.forEach(event => gameServices.removeListener(event, listeners[event]))
		}
	}, [gameServices])
}

interface ULSTransformers {
	encode: (value: any) => string
	decode: (value: string) => any
}

export const ULSBooleanTransformer = {
	encode: (value: boolean) => `${value ? 1 : 0}`,
	decode: (value: string) => value === '1'
}

export function useLocalStorage<T>(defaultValue: T, key: string, transformers?: ULSTransformers): [T, (newValue: T) => void] {
	if(typeof localStorage === 'undefined')
		return [null, null]

	let localStorageValue = localStorage.getItem(key)
	if(!localStorageValue) {
		localStorage.setItem(key, tryEncodeValue(defaultValue))
		localStorageValue = tryEncodeValue(defaultValue)
	}

	let decodedValue
	if(transformers?.decode)
		decodedValue = transformers.decode(localStorageValue)
		
	let useValue
	if(typeof decodedValue !== 'undefined')
		useValue = decodedValue
	else if(typeof localStorageValue !== 'undefined')
		useValue = localStorageValue
	else if(typeof defaultValue !== 'undefined')
		useValue = defaultValue

	const [value, setValue] = useState(useValue)

	function tryEncodeValue(value: T): string {
		if(transformers?.encode)
			return transformers.encode(value)

		return `${value}`
	}

	function setValueWraper(newValue: T) {
		setValue(newValue)
		localStorage.setItem(key, tryEncodeValue(newValue))
	}

	// return [false, () => {}]

	return [value, setValueWraper]
}

export function useRefWithCallback<T>(defaultValue: T, callback: (ref: MutableRefObject<T>) => () => void): [MutableRefObject<T>, (node: any) => void] {
	const ref = useRef<T>(defaultValue)
	const cleanup = useRef<() => void>(null)

	const setRef = useCallback(node => {
		if(node === ref.current)
			return

		if(ref.current)
			cleanup.current?.()

		// Save a reference to the node
		ref.current = node
		
		if(node)
			cleanup.current = callback(ref)
	}, [])
	
	return [ref, setRef]
}
