import React from 'react'

import { CSSProperties, useState } from 'react'

import { DotsCircleHorizontalIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'

import BlurModal from '../BlurModal'
import { useListeners } from '../../utils/hooks'
import useStore from '../../store'
import { Button } from './Menu'
import { forwardRef } from 'react'
import { useImperativeHandle } from 'react'
interface Scores {
	[key: string]: number
}

type Scoreboard = Scores[]

function mapScoreIcon(userId: string, scoreboard: Scoreboard) {
	const userIds = Object.keys(scoreboard[0])
	const otherUserId = userIds.find(_userId => _userId !== userId)

	const FALLBACK_COMPONENT = <DotsCircleHorizontalIcon style={{...styles.scoreboardResultIcon, ...styles.scoreboardResultTBDIcon}} />

	// eslint-disable-next-line react/display-name
	return (_, i: number) => {
		const roundScores = scoreboard[i]
		if(!roundScores)
			return FALLBACK_COMPONENT

		const selfScore = roundScores[userId]
		const otherScore = roundScores[otherUserId]
		if(!selfScore || !otherScore)
			return FALLBACK_COMPONENT

		const iconComponent = selfScore > otherScore ? (
			<CheckCircleIcon style={{...styles.scoreboardResultIcon, ...styles.scoreboardResultWinnerIcon}} />
		) : (
			<XCircleIcon style={{...styles.scoreboardResultIcon, ...styles.scoreboardResultLoserIcon}} />
		)

		return (
			<>
				{iconComponent}
				<p style={{...styles.scoreboardResultScoreLabel, ...(i < 2 ? { marginBottom: '1em' } : { marginBottom: 0 })}}>{selfScore?.toLocaleString()} GEMS</p>
			</>
		)
	}
}

const Results = forwardRef(function Results(props, ref) {
	const [visible, setVisible] = useState(true)
	const [loading, setLoading] = useState(true)
	const [showContinueButton, setShowContinueButton] = useState(false)
	const [secondsUntilNextRound, setSecondsUntilNextRound] = useState(0)

	const [scores, setScores] = useState<Scores>({})
	const [scoreboard, setScoreboard] = useState<Scoreboard>([])

	const me = useStore(state => state.me)
	const otherPlayer = useStore(state => state.otherPlayer)
	const gameServices = useStore(state => state.gameServices)

	useListeners({
		SCORE_UPDATE: _scores => {
			setScores(_scores)

			// Show modal
			setLoading(false)
			setVisible(true)
		},
		SCOREBOARD_UPDATE: _scoreboard => {
			setScoreboard(_scoreboard)
		},
		MATCH_UPDATE: ({ state }) => {
			switch(state) {
			case 'READY':
			case 'WAITING_FOR_PLAYERS':
				setVisible(false)

				break
			case 'DONE':
				setShowContinueButton(true)

				break
			}
		},
		ROUND_READY_COUNTDOWN: ({ seconds_left: secondsLeft }) => {
			setSecondsUntilNextRound(secondsLeft)
		},
		STEAL: () => {
			setLoading(true)
			setVisible(true)
		},
		STALEMATE: () => {
			setLoading(true)
			setVisible(true)
		},
		ROUND_READY: () => {
			setVisible(false)
		}
	})

	useImperativeHandle(ref, () => ({
		steal() {
			setLoading(true)
			setVisible(true)
		}
	}))

	function onContinue() {
		setVisible(false)
		setShowContinueButton(false)
		gameServices.emit('SEND_MESSAGE', { data: {}, type: 'LEAVE_MATCH' })
	}

	if(!scores)
		return <></>

	const userIds = Object.keys(scores)
	if(userIds.length === 0 || !me || !otherPlayer)
		return <></>

	if(scoreboard.length === 0)
		return <></>

	const latestScores = scoreboard[scoreboard.length - 1]

	// Latest Round
	const roundWinnerUserId = latestScores[userIds[0]] > latestScores[userIds[1]] ? userIds[0] : userIds[1]
	const roundLoserUserId = userIds.find(_userId => _userId !== roundWinnerUserId)
	// const didWinRound = roundWinnerUserId === me.id

	// Overall
	const overallWinnerUserId = scores[userIds[0]] > scores[userIds[1]] ? userIds[0] : userIds[1]
	const didWinOverall = overallWinnerUserId === me.id

	const isStalemate = latestScores[userIds[0]] === latestScores[userIds[1]] && latestScores[userIds[0]] === 100

	function getNameFromId(id: string) {
		if(id === me.id)
			return 'YOU'

		if(otherPlayer && id === otherPlayer.id)
			return otherPlayer.name

		return '???'
	}

	return (
		<BlurModal
			show={visible}
			style={{ ...styles.modal, pointerEvents: showContinueButton ? 'all' : 'none' }}
			innerStyle={{ padding: '3em 0' }}
		>
			{loading ? (
				<>
					<p>loading scores...</p>
				</>
			) : (
				<>
					{!showContinueButton ? (
						<>
							<h1 style={styles.roundLabel}>ROUND {scoreboard.length}/3</h1>
							{isStalemate ? (
								<>
									<h1 style={styles.resultTitleLabel}>NOBODY STOLE ANYTHING</h1>
									<h2 style={styles.resultSubtitleLabel}>YOU BOTH LEFT IT TOO LATE AND TOOK 100 GEMS</h2>
								</>
							) : (
								<>
									<h1 style={styles.resultTitleLabel}>{getNameFromId(roundWinnerUserId)} STOLE <span style={styles.gemsColor}>{latestScores[roundWinnerUserId]?.toLocaleString()} GEMS</span></h1>
									<h2 style={styles.resultSubtitleLabel}>{getNameFromId(roundLoserUserId)} made away with <span style={styles.gemsColor}>{latestScores[roundLoserUserId]?.toLocaleString()} gems</span></h2>
								</>
							)}
						</>
					) : (
						<>
							<h1 style={{...styles.resultTitleLabel, fontSize: '3em'}}>{getNameFromId(overallWinnerUserId)} WON{didWinOverall ? '!' : '	'}</h1>
							<h2 style={{...styles.resultSubtitleLabel, fontSize: '1.4em'}}>YOU WON <span style={styles.gemsColor}>{scores[me.id]?.toLocaleString()} GEMS</span></h2>
						</>
					)}

					<hr style={styles.divider} />

					{scoreboard.length > 0 && (
						<>
							<div style={styles.scoreboard}>
								<div style={styles.scoreboardHalf}>
									<h1 style={styles.scoreboardNameLabel}>YOU</h1>
									<h1 style={styles.scoreboardScoreLabel}>{scores[me.id]?.toLocaleString()} GEMS</h1>
		
									{new Array(3).fill(null).map(mapScoreIcon(me.id, scoreboard))}
								</div>
								<div style={styles.scoreboardHalf}>
									<h1 style={styles.scoreboardNameLabel}>{otherPlayer.name}</h1>
									<h1 style={styles.scoreboardScoreLabel}>{scores[otherPlayer.id]?.toLocaleString()} GEMS</h1>
		
									{new Array(3).fill(null).map(mapScoreIcon(otherPlayer.id, scoreboard))}
								</div>
							</div>
		
							<hr style={styles.divider} />
						</>
					)}

					{showContinueButton ? (
						<Button onClick={onContinue}>
							<p style={styles.buttonLabel}>DONE</p>
						</Button>
					) : (
						<>
							<p style={styles.nextRoundCountdownTitleLabel}>{secondsUntilNextRound + 1} SECOND{secondsUntilNextRound !== 0 ? 'S' : ''}</p>
							<p style={styles.nextRoundCountdownSubtitleLabel}>UNTIL ROUND {scoreboard.length + 1}/3</p>
						</>
					)}
				</>
			)}
		</BlurModal>
	)
})

export default Results

const styles: { [key: string]: CSSProperties } = {
	modal: {
		color: 'white',
		// height: 'calc(100vh - 50px - 30px)'
	},

	roundLabel: {
		margin: 0,
		// marginBottom: '1em',
		textAlign: 'center',
		fontSize: '2.5em',
		fontFamily: 'Pixel Splitter Bold'  
	},
	resultTitleLabel: {
		margin: 0,
		textAlign: 'center',
		fontSize: '1.25em',
		fontFamily: 'Pixel Splitter Bold'  
	},
	resultSubtitleLabel: {
		margin: 0,
		textAlign: 'center',
		fontSize: '0.95em',
		fontFamily: 'Pixel Splitter Bold'  
	},

	divider: {
		width: '100%',
		color: 'white',
		margin: '2.5em',
		opacity: 0.25
	},

	scoreboard: {
		display: 'flex',

		width: '100%',
		flexDirection: 'row'
	},
	scoreboardHalf: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		flexDirection: 'column',
		textAlign: 'center',

		flex: 1,
		width: '50%',
	},
	scoreboardNameLabel: {
		margin: 0,
		fontSize: '2em',
		fontFamily: 'Pixel Splitter Bold' 
	},
	scoreboardScoreLabel: {
		margin: 0,
		marginBottom: '1em',
		color: 'aqua',
		fontSize: '1.25em',
		fontFamily: 'Pixel Splitter Bold' 
	},
	scoreboardResultLabel: {
		margin: 0
	},
	scoreboardResultIcon: {
		width: '3em',
		height: '3em',
	},
	scoreboardResultWinnerIcon: {
		color: '#02ff00'
	},
	scoreboardResultLoserIcon: {
		color: '#f34343'
	},
	scoreboardResultTBDIcon: {
		color: '#acacac'
	},
	scoreboardResultScoreLabel: {
		margin: 0,
		marginTop: '0.25em',
		color: 'white',
		fontSize: '1em',
		textAlign: 'center',
		fontFamily: 'Pixel Splitter Bold'
	},

	nextRoundCountdownTitleLabel: {
		color: 'white',
		margin: 0,
		fontSize: '2.5em',
		textAlign: 'center',
		fontFamily: 'Pixel Splitter Bold'
	},
	nextRoundCountdownSubtitleLabel: {
		color: 'white',
		margin: 0,
		fontSize: '1.25em',
		textAlign: 'center',
		fontFamily: 'Pixel Splitter Bold'
	},

	buttonLabel: {
		color: 'black',
		margin: 0,
		fontSize: '1.5em',
		textAlign: 'center',
		fontFamily: 'Pixel Splitter Bold'  
	},

	gemsColor: {
		color: 'aqua'
	}
}
