import create from 'zustand'

import EventEmitter from 'eventemitter3'
import { Me, User } from '../components/Controller'

interface GameLeaderboardEntry {
	position: number
	name: string
	score: number

	me?: boolean
}

interface GameLeaderboardPart {
	top: GameLeaderboardEntry[]
	personal?: GameLeaderboardEntry[]
}

interface GameLeaderboard {
	daily: GameLeaderboardPart
	total: GameLeaderboardPart
	cumulative: GameLeaderboardPart
}

export type GameLeaderboardType = keyof GameLeaderboard

interface GameState {
	me: Me
	otherPlayer: User

	entries: number[]
	leaderboard: GameLeaderboard

	connectedPlayerCount: number
	freeEntriesRemaining: number

	gameServices: EventEmitter

	setMe: (me: Me) => void
	setOtherPlayer: (me: Me) => void

	setConnectedPlayerCount: (connectedPlayerCount: number) => void
	setFreeEntriesRemaining: (freeEntriesRemaining: number) => void

	setEntries: (entries: number[]) => void
	setLeaderboard: (leaderboard: GameLeaderboard) => void

	setGameServices: (gameServices: EventEmitter) => void
}

const useStore = create<GameState>(set => ({
	me: null,
	otherPlayer: null,

	entries: [],
	leaderboard: null,
	
	connectedPlayerCount: 0,
	freeEntriesRemaining: 0,

	gameServices: null,

	setMe: me => set({ me }),
	setOtherPlayer: otherPlayer => set({ otherPlayer }),

	setConnectedPlayerCount: connectedPlayerCount => set({ connectedPlayerCount }),
	setFreeEntriesRemaining: freeEntriesRemaining => set({ freeEntriesRemaining }),

	setEntries: entries => set({ entries }),
	setLeaderboard: leaderboard => set({ leaderboard }),

	setGameServices: gameServices => set({ gameServices })
}))

export default useStore
