import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import * as THREE from 'three'

import { Text, useFBX } from '@react-three/drei'
import { animated, easings, useSpring } from '@react-spring/three'

import { User } from '../Controller'
import { useListeners } from '../../utils/hooks'
import { DEV_DISABLE_DRIVE, DRIVING_EASING } from '../../utils/constants'

interface CarProps {
	user: User
	score: number
	isLeft: boolean
}

const TAUNT_DURATION = 500
export const AnimatedPrimitive = animated.primitive as unknown as any

export default forwardRef(function Car({ user, score, isLeft }: CarProps, ref) {
	const fbx = useFBX('/models/cars/SportsCar.fbx').clone()
	const carPos = useRef(null)

	const xPos = isLeft ? -0.5 : 0.5
	const config = { easing: easings.easeOutQuint, duration: TAUNT_DURATION }

	const tauntIndex = useRef(0)

	const [showNametag, setShowNametag] = useState(false)

	const DEFAULT_CAR_GROUP_POSITION = [xPos, 0, -0.85]
	const [{ position }, setPosition] = useSpring(() => ({
		position: DEFAULT_CAR_GROUP_POSITION,
		config
	}))

	const DEFAULT_CAR_POSITION = [xPos, 0, 0]
	const [{ position: carPosition }, setCarPosition] = useSpring(() => ({
		position: DEFAULT_CAR_POSITION,
		config
	}))

	const DEFAULT_CAR_ROTATION = [-(Math.PI / 2), 0, 0]
	const [{ rotation: carRotation }, setCarRotation] = useSpring(() => ({
		rotation: DEFAULT_CAR_ROTATION,
		config
	}))

	function reset() {
		setPosition.stop()
		setPosition.set({ position: DEFAULT_CAR_GROUP_POSITION })
	}

	useListeners({
		ROUND_START: () => {
			if(DEV_DISABLE_DRIVE)
				return

			setPosition.set({ position: DEFAULT_CAR_GROUP_POSITION })

			setPosition.start({ position: [xPos, 0, -508.5], config: { duration: 30 * 1000, easing: DRIVING_EASING } })
		},
		ROUND_READY: () => {
			setPosition.stop()
			setPosition.set({ position: DEFAULT_CAR_GROUP_POSITION })
		},
		MATCH_UPDATE: ({ state }) => {
			switch(state) {
			case 'READY':
				reset()
				setShowNametag(true)

				break
			case 'WAITING_FOR_PLAYERS':
				reset()

				break
			}
		},
		MATCH_LEFT: () => {
			reset()
			setShowNametag(false)
		},
		STEAL: () => {
			setPosition.stop()
		},
		STALEMATE: () => {
			setPosition.stop()
		}
	})

	const [{ rotation }, setRotation] = useSpring(() => ({
		rotation: [0, 0, 0],
		config,
		onRest: () => setRotation.start({ rotation: [0, 0, 0], config: {...config, duration: config.duration * 2} })
	}))

	fbx.children.forEach(mesh => {
		if(!(mesh instanceof THREE.Mesh))
			return

		mesh.material = new THREE.MeshBasicMaterial({
			color: '#4B4B4B',
			// opacity: 0.2,
			// transparent: true,
			wireframe: true
			// wireframe: true,
			// wireframeLinewidth: 3
		})
	})

	const TAUNTER_POS_OFFSET = 1
	const TAUNTEE_POS_OFFSET = 0.5

	const TAUNTER_ROT_OFFSET = .25
	const TAUNTEE_ROT_OFFSET = 0

	useImperativeHandle(ref, () => ({
		steal(didSteal: boolean) {
			setPosition.stop()
		},
		// didTaunt = did this car initiate the taunt
		taunt(didTaunt: boolean) {
			const TAUNT_Z_OFFSET = [0, 1, -1][tauntIndex.current] || 0

			if(didTaunt) {
				if(isLeft) {
					setCarPosition.start({ position: [xPos + TAUNTER_POS_OFFSET, 0, 0] })
					setCarRotation.start({ rotation: [-(Math.PI) / 2, TAUNTER_ROT_OFFSET, TAUNT_Z_OFFSET]})
				} else {
					setCarPosition.start({ position: [xPos - TAUNTER_POS_OFFSET, 0, 0] })
					setCarRotation.start({ rotation: [-(Math.PI) / 2, -TAUNTER_ROT_OFFSET, TAUNT_Z_OFFSET]})
				}
			} else {
				if(isLeft) {
					setCarPosition.start({ position: [xPos - TAUNTEE_POS_OFFSET, 0, 0] })
					setCarRotation.start({ rotation: [-(Math.PI) / 2, TAUNTEE_ROT_OFFSET, 0]})
				} else {
					setCarPosition.start({ position: [xPos + TAUNTEE_POS_OFFSET, 0, 0] })
					setCarRotation.start({ rotation: [-(Math.PI) / 2, -TAUNTEE_ROT_OFFSET, 0]})
				}
			}

			setTimeout(() => {
				setCarPosition.start({ position: DEFAULT_CAR_POSITION })
				setCarRotation.start({ rotation: DEFAULT_CAR_ROTATION })
			}, 100)

			tauntIndex.current += 1
			if(tauntIndex.current > 2)
				tauntIndex.current = 0
		}
	}))

	// const NAME_LABEL_X_POS = 0.575
	const NAME_LABEL_X_POS = 0.4

	const SCORE_LABEL_X_POS = 0.4
	
	const LABEL_Y_ROT = 2

	const NAME_LABEL_Z_POS = 3.75
	const SCORE_LABEL_Z_POS = NAME_LABEL_Z_POS + 0.5

	return (
		<animated.group {...{ position, rotation } as unknown as any}>
			<AnimatedPrimitive
				ref={carPos}

				scale={0.01}
				// position={[-3.4, 1, 0]}

				position={carPosition}
				rotation={carRotation}

				wireframe={true}

				object={fbx}
				dispose={null}
			/>
			{(user && showNametag) && (
				<>
					<Text
						font="/fonts/PixelSplitter-Bold.woff"

						color="#4B4B4B" // default
						anchorX={isLeft ? 'right' : 'left'} // default
						// anchorX={'center'}
						anchorY="middle" // default
						fontSize={0.4}
			
						position={[isLeft ? NAME_LABEL_X_POS : -NAME_LABEL_X_POS, 0.5, NAME_LABEL_Z_POS]}
						rotation={[-(Math.PI / 2) / LABEL_Y_ROT, 0, 0]}
					>
						{isLeft ? 'YOU' : user?.name}
					</Text>
					<Text
						font="/fonts/PixelSplitter-Bold.woff"

						color="#4B4B4B" // default
						// anchorX={'center'}
						anchorX={isLeft ? 'right' : 'left'} // default
						anchorY="middle" // default
						fontSize={0.175}

						position={[isLeft ? SCORE_LABEL_X_POS : -SCORE_LABEL_X_POS, 0.425, SCORE_LABEL_Z_POS]}
						rotation={[-(Math.PI / 2) / LABEL_Y_ROT, 0, 0]}
					>
						{(score || 0).toLocaleString()} GEMS
					</Text>
				</>
			)}
		</animated.group>
	)
})