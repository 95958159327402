import React from 'react'

import Head from 'next/head'

import LoadingScreen from '../components/LoadingScreen'
import GameController from '../components/Controller'
import ExtraGameServices, { GameConnectionState } from '../components/ExtraGameServices'

import { useToken } from '../utils/hooks'

type LoadingCopies = {
	[key in GameConnectionState]: string
}

const LOADING_COPIES: LoadingCopies = {
	connecting: 'Connecting to Extra Game Services...',
	authenticating: 'Authenticating with Extra Game Services...',
	'acquiring-details': 'Acquiring game details...',
	connected: 'Connected to Extra Game Services',
	disconnected: 'Disconnected from EGS—reconnecting...'
}

export default function GemHeist() {
	// Get + wipe token from url
	const token = useToken()
	if(!token)
		return <></>

	return (
		<>
			<Head>
				<link rel="stylesheet" href="https://use.typekit.net/rah5eyi.css" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
			</Head>
			<ExtraGameServices {...{token}}>
				{({ ref, connectionState }) => (
					<>
						<GameController gameServicesRef={ref} {...{connectionState}} />
						<LoadingScreen show={connectionState !== 'connected'} message={LOADING_COPIES[connectionState]} />
					</>
				)}
			</ExtraGameServices>
		</>
	)
}
