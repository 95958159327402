import React, { forwardRef, ReactNode, useLayoutEffect, useRef } from 'react'
import { PerspectiveCamera as PerspectiveCameraImpl } from 'three'

import mergeRefs from 'react-merge-refs'
import { useThree } from '@react-three/fiber'
import { animated } from '@react-spring/three'

type Props = JSX.IntrinsicElements['perspectiveCamera'] & {
  makeDefault?: boolean
  manual?: boolean
  children?: ReactNode
}

export const AnimatedPerspectiveCamera = forwardRef(function AnimatedPerspectiveCamera({ makeDefault, ...props }: Props, ref) {
	const set = useThree(({ set }) => set)
	const camera = useThree(({ camera }) => camera)
	const size = useThree(({ size }) => size)
	const cameraRef = useRef<PerspectiveCameraImpl>()

	useLayoutEffect(() => {
		const { current: cam } = cameraRef
		if (cam && !props.manual) {
			cam.aspect = size.width / size.height
			cam.updateProjectionMatrix()
		}
	}, [size, props])

	useLayoutEffect(() => {
		if (makeDefault && cameraRef.current) {
			const oldCam = camera
			set(() => ({ camera: cameraRef.current }))
			return () => set(() => ({ camera: oldCam }))
		}
	}, [camera, cameraRef, makeDefault, set])

	return <animated.perspectiveCamera ref={mergeRefs([cameraRef, ref])} {...props} />
})
