import React, { useRef, useState } from 'react'

import { useSpring } from 'react-spring'

import useStore from '../../../store'
import { useListeners } from '../../../utils/hooks'
import { AnimatedText } from '../../../utils/helpers'

const DEFAULT_POSITION = [0, 1.6, -4]
const DEFAULT_HIDDEN_POSITION = [0, 1.6 * 3, -4]

export default function SecondsLeftLabel() {
	const [secondsLeft, setSecondsLeft] = useState(0)

	const [{ position }, setPosition] = useSpring(() => ({ position: DEFAULT_HIDDEN_POSITION }))

	const gameServices = useStore(state => state.gameServices)

	const msUpdateInterval = useRef<NodeJS.Timeout>(null)

	function resetMsUpdateInterval() {
		if(!msUpdateInterval.current)
			return 

		clearInterval(msUpdateInterval.current)
		msUpdateInterval.current = null
	}

	useListeners({
		ROUND_START: () => {
			setPosition.start({ position: DEFAULT_POSITION })
		},
		SCORE_UPDATE: () => {
			resetMsUpdateInterval()
			setPosition.set({ position: DEFAULT_HIDDEN_POSITION })
		},
		MATCH_UPDATE: ({ state }) => {
			switch(state) {
			case 'READY':
			case 'WAITING_FOR_PLAYERS':
				resetMsUpdateInterval()
				setPosition.set({ position: DEFAULT_HIDDEN_POSITION })

				break
			}
		},
		MATCH_LEFT: () => {
			resetMsUpdateInterval()
			setPosition.set({ position: DEFAULT_HIDDEN_POSITION })
		},
		ROUND_UPDATE: ({ seconds_left: _secondsLeft }) => {
			setSecondsLeft(_secondsLeft + 1) // +1 because we do the -0.01 hacky hack

			resetMsUpdateInterval()

			msUpdateInterval.current = setInterval(() => {
				setSecondsLeft(sl => {
					if(sl <= 0)
						return 0

					return sl - 0.01
				})
			}, 10)
		}
	}, gameServices)

	return (
		<AnimatedText
			font="/fonts/PixelSplitter-Bold.woff"

			color="aqua" // default
			anchorX="center" // default
			anchorY="middle" // default
			fontSize={0.25}

			position={position as unknown as any}
		>
			{secondsLeft.toFixed(2).toLocaleString()} seconds left
		</AnimatedText>
	)
}
