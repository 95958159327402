import React, { CSSProperties } from 'react'

export default function Swoosh({ color, style }: { color?: string, style?: CSSProperties }) {
	return (
		<svg style={style} width="60" height="60" viewBox="0 0 345 215" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="347" height="215">
				<rect x="0" width="345" height="215" fill="#C4C4C4"></rect>
			</mask>
			<g mask="url(#mask0)">
				<mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="-173" y="50" width="694" height="115">
					<path d="M-166 157.5H80.8991C107.289 157.5 132.597 147.398 151.257 129.416L196.743 85.5834C215.403 67.6015 240.712 57.5 267.101 57.5H514" stroke="#717171" strokeWidth="14" strokeLinecap="round"></path>
				</mask>
				<g mask="url(#mask1)">
					<rect x="0" width="345" height="215" fill={color || '#383838'}></rect>
					<rect x="0" width="120" height="215" fill="url(#paint0_linear)" className="fill"></rect>
				</g>
			</g>
			<defs>
				<linearGradient id="paint0_linear" x1="2.35084e-06" y1="58" x2="84" y2="58" gradientUnits="userSpaceOnUse">
					<stop stopColor="#949494" stopOpacity="0"></stop>
					<stop offset="0.468807" stopColor="#949494" stopOpacity="0.92"></stop>
					<stop offset="1" stopColor="#949494" stopOpacity="0"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
