import { easings } from '@react-spring/three'

// export const SPEED = 0.4
export const MIN_SWIPE_DISTANCE = 75

// const ROAD_START_VIEW_OFFSET = 6

// export const ROAD_LENGTH = 500 + ROAD_START_VIEW_OFFSET
// export const ROAD_START_OFFSET = -(ROAD_LENGTH / 2) + ROAD_START_VIEW_OFFSET

// export const ROAD_CAR_OFFSET = 10
// export const ROAD_CAMERA_START_OFFSET = 8
// export const ROAD_CAMERA_END_OFFSET = ROAD_CAMERA_START_OFFSET + ROAD_CAR_OFFSET

// export const ANIMATION_LENGTH = 30

// export const OBJECT_COUNT = 300
// export const MIN_RANDOM_DISTANCE = 4
// export const MAX_RANDOM_DISTANCE = 100

export const ROAD_LENGTH = 600
export const ROAD_OFFSET = (ROAD_LENGTH / 2) - 10

export const ANIMATION_LENGTH = 1000 * 7.5
export const TITLE_ANIMATION_LENGTH = ANIMATION_LENGTH// (1000 * 10) - ANIMATION_LENGTH

export const ANIMATE_TO = -5
export const ANIMATE_TO_TITLE = ANIMATE_TO - 7.5

export const DEFAULT_CAMERA_POSITIONS = {
	game: [0, 2, 7],
	menu: [0, 3, ANIMATE_TO],
	ending: [0, 2, -500]
}

export const DEFAULT_CAMERA_ROTATIONS = {
	game: [0, 0, 0],
	menu: [-0.185, 0, 0],
	ending: [0, 0, 0]
}

export const LEADERBOARD_ANIMATION_CONFIG = {
	duration: 1500,
	easing: easings.easeOutQuint
}

// easeInOutSine
export const DRIVING_EASING = (t: number) => easings.easeInQuad(t)

export const DEV_DISABLE_DRIVE = false
