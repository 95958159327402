import React, { MutableRefObject, useState } from 'react'
import EventEmitter from 'eventemitter3'

import Scene from './Scene'
import ConfirmationScreen from './ConfirmationScreen'

import useStore from '../store'
import { useListeners, useLocalStorage } from '../utils/hooks'
import { GameConnectionState } from './ExtraGameServices'
import MenuModal from './Scene/MenuModal'
import Rules from './Scene/Content/Rules'

interface GameControllerProps {
	gameServicesRef: MutableRefObject<EventEmitter<string | symbol, any>>
	connectionState: GameConnectionState
}

// Generic copies
export interface User {
	id: string
	name: string
}

export interface Me extends User {
	point_balance: number
}

export default function GameController({ gameServicesRef, connectionState }: GameControllerProps) {
	const setOtherPlayer = useStore(state => state.setOtherPlayer)

	// Listeners
	const listeners = {
		// Entry stuff
		ENTRY_REDEEMED: () => gameServicesRef.current.emit('SEND_MESSAGE', { data: {}, type: 'JOIN_MATCH' }),
	
		// Match stuff
		MATCH_JOINED: data => setOtherPlayer(data.players[0]),

		// Player stuff
		PLAYER_JOINED_MATCH: data => setOtherPlayer(data),
		MATCH_PLAYERS_UPDATE: data => setOtherPlayer(data[0]),
	}

	// EGS Handler
	useListeners(listeners, gameServicesRef.current)

	const [showTerms, setShowTerms] = useState(false)
	const [hasAcceptedTerms, sethasAcceptedTerms] = useLocalStorage('not-reviewed', 'has_accepted_terms')
	const [hasUnderstoodEpilepsy, setHasUnderstoodEpilepsy] = useLocalStorage('not-reviewed', 'has_understood_epilepsy')

	if(connectionState !== 'connected')
		return <></>

	console.log({ hasUnderstoodEpilepsy })

	// Epilepsy Warning
	if(hasUnderstoodEpilepsy === 'not-reviewed')
		return (
			<ConfirmationScreen
				title='Photosensitivity Warning'
				body='A small percentage of people may experience seizures when exposed to certain lights, patterns, or images, even with no history of epilepsy or seizures.'

				onConfirm={() => setHasUnderstoodEpilepsy('confirmed')}
				confirmLabel='Continue'
			/>
		)

	if(showTerms)
		return (
			<MenuModal title='Rules' backLabel={'Back'} onClose={() => setShowTerms(false)}>
				<Rules />
				<img
					src='/blur.jpg'
					style={{
						position: 'fixed',
						top: '0',
						left: '0',
						width: '100%',
						height: '100%',
						objectFit: 'cover',
						zIndex: -1
					}}
				/>
			</MenuModal>
		)

	if(hasAcceptedTerms !== 'confirmed')
		return (
			<ConfirmationScreen
				title='T&amp;C'
				body='By continuing, you agree to the T&amp;Cs which you can view below'

				onConfirm={() => sethasAcceptedTerms('confirmed')}
				confirmLabel='Continue'

				onDeny={() => setShowTerms(true)}
				denyLabel='View T&amp;Cs'
			/>
		)

	return <Scene />
}
