import { useEffect, useRef, useState } from 'react'

import pako from 'pako'
import EventEmitter from 'eventemitter3'

import useStore from '../store'

interface ExtraGameServicesProps {
  token: string
  children?
}

export type GameConnectionState = 'connecting' | 'authenticating' | 'acquiring-details' | 'connected' | 'disconnected'

export const IS_PROD = true
export const FORCE_PROD_URL = false

const USE_ZLIB_COMPRESSION = true
const COMPRESSION = USE_ZLIB_COMPRESSION ? '' : '?compression=utf8_json'

export default function ExtraGameServices({ token, children }: ExtraGameServicesProps) {
	const emitter = useRef<EventEmitter>(new EventEmitter())

	const setMe = useStore(state => state.setMe)
	const setLeaderboard = useStore(state => state.setLeaderboard)
	const setGameServices = useStore(state => state.setGameServices)
	const setConnectedPlayerCount = useStore(state => state.setConnectedPlayerCount)

	const entries = useStore(state => state.entries)
	const freeEntriesRemaining = useStore(state => state.freeEntriesRemaining)

	const setEntries = useStore(state => state.setEntries)
	const setFreeEntriesRemaining = useStore(state => state.setFreeEntriesRemaining)

	const [connectionState, setConnectionState] = useState<GameConnectionState>('connecting')

	const heartbeatInterval = useRef(null)
	const reconnectInterval = useRef(null)

	const reconnectIntervalMs = useRef(5000)

	useEffect(() => {
		let socket: WebSocket

		// Socket stuff
		function setupSocket() {
			if(socket) {
				socket.close(1000)
				socket = null
			}

			socket = new WebSocket(`${(IS_PROD || FORCE_PROD_URL) ? 'wss://mmo.extra.app' : 'ws://192.168.1.131:4500'}${COMPRESSION}`)

			if(USE_ZLIB_COMPRESSION)
				socket.binaryType = 'arraybuffer'

			socket.onopen = () => {
				if(!IS_PROD)
					console.log('ws opened')
			}
      
			socket.onmessage = ({ data: _data }) => {
				if(USE_ZLIB_COMPRESSION)
					_data = pako.inflate(_data, { to: 'string' })

				const { data, type } = JSON.parse(_data)
				console.log('got', type, data)
  
				switch(type) {
				case 'HELLO':
					heartbeatInterval.current = setInterval(() => sendMessage({}, '💓'), data.heartbeat)
					reconnectIntervalMs.current = data.reconnect
  
					sendMessage({ token }, 'IDENTIFY')
					setConnectionState('authenticating')
  
					break
				case 'IDENTIFY':
					setMe(data)
					sendMessage({ game_id: 2 }, 'JOIN_GAME')
					setConnectionState('acquiring-details')
          
					break
				case 'GAME_DETAILS':
					setEntries(data.available_entries)
					setFreeEntriesRemaining(data.free_entries_remaining)

					sendMessage({}, 'FETCH_LEADERBOARD')
					setConnectionState('connected')
					setConnectedPlayerCount(data.online_player_count)

					break
				case 'PLAYER_CONNECTED': 
				case 'PLAYER_DISCONNECTED': 
					setConnectedPlayerCount(data.online_player_count)
					
					break
				case 'ENTRIES_PURCHASED': {
					const currentState = useStore.getState()
					setMe({ ...currentState.me, point_balance: data.new_points_balance })
					setEntries(Array.from(new Set([...entries, ...data.entry_ids])))

					if(freeEntriesRemaining > 0)
						setFreeEntriesRemaining(freeEntriesRemaining - 1)

					break
				}
				case 'ENTRY_CONSUMED':
				case 'ENTRY_REFUNDED':
					setEntries(entries.filter(entryId => data.entry_id !== entryId))
        
					break
				case 'LEADERBOARD_UPDATE':
					setLeaderboard(data)

					break
				case 'ERROR':
					alert(data.message)

					break
				}
  
				emitter.current.emit(type, data)
			}
  
			socket.onclose = ({ code }) => {
				if(!IS_PROD)
					console.log('ws closed')

				// Code 1000 = was meant to disconnect
				if(code !== 1000)
					setupReconnect()

				setConnectionState('disconnected')
			}
  
			socket.onerror = () => {
				if(!IS_PROD)
					console.log('ws errored')
			}
		}
    
		// Message Stuff
		function sendMessage(data, type) {
			if(!socket || socket.readyState !== WebSocket.OPEN) {
				console.log('websocket not open to send', type, data)
				return
			}

			let send: any

			send = JSON.stringify({ data, type })

			if(USE_ZLIB_COMPRESSION)
				send = pako.deflate(send)

			socket.send(send)
		}

		function messageListener({ data, type }) {
			console.log('send', type, data)
			sendMessage(data, type)
		}

		emitter.current.addListener('SEND_MESSAGE', messageListener)

		// Reconnection
		function setupReconnect() {
			console.log('called setupReconnect')
			reconnectInterval.current = setTimeout(() => setupSocket(), reconnectIntervalMs.current)
		}

		setupSocket()
		setGameServices(emitter.current)

		return () => {
			// Remove listeners
			emitter.current.removeListener('SEND_MESSAGE', messageListener)

			// Reset interval
			clearInterval(heartbeatInterval.current)
			heartbeatInterval.current = null

			// Close socket
			socket.close(1000)
			socket = null
		}
	}, [])

	return children({
		ref: emitter,
		connectionState
	})
}
