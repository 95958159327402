import React, { CSSProperties } from 'react'
import { Button } from './Scene/Menu'

interface ConfirmationScreenProps {
	title?: string
	body: string

	confirmLabel?: string
	denyLabel?: string

	onConfirm: () => void
	onDeny?: () => void

	children?: React.ReactChildren
}

export default function ConfirmationScreen({ title, body, confirmLabel, denyLabel, onConfirm, onDeny }: ConfirmationScreenProps) {
	return (
		<div style={styles.modalWrapper}>
			<div style={styles.modal}>
				<h1 style={{...styles.label, fontSize: '1.5em', marginBottom: '0.25em'}}>{title}</h1>
				<h2 style={{...styles.label, fontSize: '1em', marginBottom: '1em'}}>{body}</h2>
				<div style={styles.modalButtons}>
					<Button additionalStyles={{ ...styles.modalButton, marginBottom: '10px' }} onClick={onConfirm}>
						<p style={styles.buttonLabel}>{confirmLabel || 'Accept'}</p>
					</Button>
					{denyLabel && (
						<Button additionalStyles={styles.modalButton} onClick={onDeny}>
							<p style={styles.buttonLabel}>{denyLabel || 'Deny'}</p>
						</Button>
					)}
				</div>
			</div>
			<img src='/blur.jpg' style={styles.backgroundImage} />
		</div>
	)
}

const styles: { [key: string]: CSSProperties } = {
	modalWrapper: {
		position: 'fixed',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',

		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	modal: {
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',

		textAlign: 'center',
		borderRadius: '15px',
		padding: '1.5em',

		width: '100%',
		margin: '0 1em'
	},
	label: {
		margin: 0,
		// marginBottom: '1em',
		color: 'white',
		// fontSize: '1.25em',
		fontFamily: 'Pixel Splitter Bold'
	},
	modalButtons: {
		// display: 'flex'
	},
	modalButton: {
		// width: '100%'
	},
	buttonLabel: {
		color: 'black',
		margin: 0,
		fontSize: '1.5em',
		textAlign: 'center',
		fontFamily: 'Pixel Splitter Bold'
	},
	backgroundImage: {
		position: 'fixed',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		zIndex: -1
	}
}
