import React, { useState } from 'react'

import { easings, SpringConfig, useSpring } from 'react-spring'

import useStore from '../../../store'
import { useListeners } from '../../../utils/hooks'
import { AnimatedText } from '../../../utils/helpers'

const DEFAULT_POSITION: [x: number, y: number, z: number] = [0, 2, -4]
const DEFAULT_HIDDEN_POSITION: [x: number, y: number, z: number] = [0, 2 * 3, -4]

export default function ScoreLabel() {
	const [prizePool, setPrizePool] = useState(0)

	const config: SpringConfig = { duration: 500, easing: easings.easeOutQuad }
	const [{ position }, setPosition] = useSpring(() => ({ position: DEFAULT_HIDDEN_POSITION, config }))
	const [{ fontSize }, setFontSize] = useSpring(() => ({ fontSize: 0.5, config }))

	const gameServices = useStore(state => state.gameServices)

	useListeners({
		ROUND_START: () => {
			setPosition.start({ position: DEFAULT_POSITION })
		},
		SCORE_UPDATE: () => {
			setPosition.set({ position: DEFAULT_HIDDEN_POSITION })
		},
		MATCH_UPDATE: ({ state }) => {
			switch(state) {
			case 'READY':
			case 'WAITING_FOR_PLAYERS':
				setPosition.set({ position: DEFAULT_HIDDEN_POSITION })

				break
			}
		},
		MATCH_LEFT: () => {
			setPosition.set({ position: DEFAULT_POSITION })
			setPosition.set({ position: DEFAULT_HIDDEN_POSITION })
		},
		ROUND_UPDATE: ({ prize_pool: _prizePool }) => {
			setPrizePool(_prizePool)

			const isLargerPool = _prizePool > 625

			if(isLargerPool) {
				for(let i = 0; i < 2; i++) {
					setTimeout(() => {
						setFontSize.set({ fontSize: 0.475 })
						setFontSize.start({ fontSize: 0.4, config: { duration: 500 } })
					}, i * 500)
				}
			} else {
				setFontSize.set({ fontSize: 0.55 })
				setFontSize.start({ fontSize: 0.5, config: { duration: 1000 } })
			}
		}
	}, gameServices)

	return (
		<AnimatedText
			font="/fonts/PixelSplitter-Bold.woff"

			color="aqua" // default
			anchorX="center" // default
			anchorY="middle" // default

			fontSize={fontSize as unknown as any}
			position={position as unknown as any}
		>
			{prizePool.toLocaleString()} GEMS
		</AnimatedText>
	)
}
