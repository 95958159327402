import React, { CSSProperties } from 'react'

import { animated, useSpring } from 'react-spring'

interface BlurModalProps {
  show: boolean
  height?: string

  style?: CSSProperties
	innerStyle?: CSSProperties

	removeShadow?: boolean

  children?
}

export default function BlurModal({ show, height, children, style, innerStyle, removeShadow }: BlurModalProps) {
	const animatedStyles = useSpring({
		to: show ? {
			// filter: 'blur(0px)',
			transform: 'scale(1) translateY(0px)',
			opacity: 1
		} : {
			// filter: 'blur(100px)',
			transform: 'scale(0.9) translateY(200px)',
			opacity: 0
		},
		config: {
			duration: 0
			// type: 'spring',
			// stiffness: 500,
			// damping: 25,
			// restDelta: .5,
			// restSpeed: 10
		}
	})

	return (
		<div style={{...styles.modalWrapper, ...style, pointerEvents: show ? 'all' : 'none'}}>
			<animated.div style={{...styles.modal, height, ...animatedStyles, ...innerStyle, ...(removeShadow ? { boxShadow: null } : {})}}>
				{children}
			</animated.div>
		</div>
	)
}

const BORDER_WIDTH = 0.1
const PADDING = 15 - BORDER_WIDTH // 0.1 is the border

const styles: { [key: string]: CSSProperties } = {
	modalWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',

		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		marginTop: PADDING,
		marginLeft: PADDING,
		marginRight: PADDING,
		marginBottom: PADDING,
		width: `calc(100vw - ${PADDING * 2}px)`,
		height: `calc(100vh - ${PADDING * 2}px)`,
		borderRadius: 15,
		background: 'rgba(0,0,0,0.8)',
		// backdropFilter: 'saturate(180%) blur(20px)',
		// WebkitBackdropFilter: 'saturate(180%) blur(20px)',
		boxShadow: '0 0 65px black',
		border: `${BORDER_WIDTH}px solid rgba(38, 38, 38, 0.3)`
	}
}
