import React, { CSSProperties } from 'react'

export default function Rules() {
	return (
		<div style={styles.wrapper}>
			<h2 style={{ marginTop: 0 }}>EXTRA REWARDS GEM HEIST GAME (<strong>THE&nbsp;&quot;GAME OF SKILL PROMOTION&quot;</strong>) OFFICIAL RULES</h2>
			<p>NO PURCHASE IS NECESSARY TO ENTER OR WIN.</p>
			<ol start={1} type="1">
				<li><strong>Eligibility:&nbsp;</strong>This is a game of skill. Chance plays no part in determining the winner. Each entry will be based upon individual merit<strong>&nbsp;</strong>as evidenced by the highest score within the specified period of time.&nbsp;To enter and be eligible to win a prize, you must be a legal resident of the 50 United States, or Washington, D.C. (excluding residents of Puerto Rico, the U.S. Virgin Islands and other U.S. territories and possessions, and where prohibited by law), have a valid email address, age 18 years or older, and be an Extra Card member (the “Member”) at the time of entry. Employees of Extra (the “Sponsor”), their respective affiliates, subsidiaries, advertising and promotion agencies, suppliers and their immediate family and/or those living in the same household of each are not eligible to participate in the&nbsp;Game of Skill Promotion.&nbsp;The Game of Skill Promotion&nbsp;is subject to all applicable federal, state and local laws and regulations. Void where prohibited.</li>
			</ol>
			<ol start={2} type="1">
				<li><strong>Agreement to Rules:&nbsp;</strong>By participating, you agree to be fully unconditionally bound by these Rules, and you represent and warrant that you meet the eligibility requirements set forth herein. In addition, you agree to accept the decisions of Extra, as final and binding as it relates to the content. The&nbsp;Game of Skill Promotion&nbsp;is subject to all applicable federal, state and local laws.</li>
			</ol>
			<ol start={3} type="1">
				<li><strong>Game of Skill Promotion Period:&nbsp;</strong>The Game of Skill Promotion begins on or about January 28, 2022 <strong></strong>5:00 PM PST and ends on January 30, 2022 11:59 PM PST. All entries must be received during the Game of Skill Promotion Period. For purposes of these Official Rules, all times and days are PST. Sponsor&#x27;s computer is the official time-keeping device for the Game of Skill Promotion.</li>
			</ol>
			<ol start={4} type="1">
				<li>
					<strong>How to Enter:</strong>&nbsp;Access to the Extra app is required.&nbsp;To obtain access to the Extra app, you must be an Extra Card Member.&nbsp;You may become a Member by visiting&nbsp;<a href="http://www.extra.app/">www.extra.app</a>, clicking on the “Apply Now&quot; link and providing the requested information on the registration form.&nbsp;To obtain one (1) entry, open Extra, click on the&nbsp;Game of Skill Promotion&nbsp;link from the Home tab, and redeem&nbsp;nine hundred (900) points&nbsp;(the “Game of Skill Promotion&nbsp;Entry”) for during the&nbsp;Game of Skill Promotion&nbsp;Period. All fields are mandatory unless marked as optional.
				</li>
			</ol>
			<p>The&nbsp;Game of Skill Promotion&nbsp;must be entered by submitting an entry using the online form provided on this&nbsp;Game of Skill Promotion&nbsp;site. The entry must fulfill all&nbsp;Game of Skill Promotion&nbsp;requirements, as specified, to be eligible to win a prize. Entries that are not complete or do not adhere to the rules or specifications may be disqualified at the sole discretion of Extra. You may enter&nbsp;multiple times but&nbsp;you must fill in the information requested. You may not enter more times than indicated by using multiple email addresses, identities or devices in an attempt to circumvent the rules. If you use fraudulent methods or otherwise attempt to circumvent the rules your submission may be removed from eligibility at the sole discretion of Extra.</p>
			<ol start={5} type="1">
				<li>
					<strong>Prizes:</strong> Prizes for the Game of Skill Promotion consist of a Grand Prize and 100k Score Prize.
					<ol start={1} type="a">
						<li>Grand Prize - One (1) Overall Winner Prize: The eligible entrant that finishes with the accumulated highest score for the entire Game of Skill Promotion Period and wins any tiebreakers, will win one (1) iPhone 13 Pro Max (256 GB) valued at $1,199.</li>
					</ol>
					<ol start={2} type="a">
						<li>100k Score Prize - Any eligible entrant that reaches a score of one hundred thousand (100,000) during the Game of Skill Promotion Period, will win one (1) Extra Beanie valued at $25.</li>
					</ol>
				</li>
			</ol>
			<p>The Tiebreaker Procedure is set by the Sponsor.&nbsp;Actual/appraised value may differ at time of prize award. The specifics of the prizes shall be solely determined by the Sponsor. The prizes will be shipped by February 3, 2022 to the winner’s address on file, unless stated otherwise. No cash or other prize substitution permitted except at Sponsor&#x27;s discretion. Each prize is non-transferable. Any and all prize related expenses, including without limitation any and all federal, state, and/or local taxes shall be the sole responsibility of the winner. No substitution of prize or transfer/assignment of prize to others or request for the cash equivalent by winner is permitted. Acceptance of prize constitutes permission for Extra to use the winner&#x27;s name, likeness, and entry for purposes of advertising and trade without further compensation, unless prohibited by law.</p>
			<ol start={6} type="1">
				<li><strong>Winner Selection and Notification:</strong>&nbsp;The winners will be notified via email&nbsp;or phone&nbsp;to the email address&nbsp;or phone number on file&nbsp;within twenty four (24) hours following the winner selection. Extra shall have no liability for the winner&#x27;s failure to receive notices due to winners&#x27; spam, junk e-mail or other security settings or for the winner&#x27;s provision of incorrect or otherwise non-functioning contact information. If the selected winner cannot be contacted, is ineligible, fails to claim the prize within twenty four (24) hours from the time award notification was sent, or fails to timely return a completed and executed declaration and releases as required, prize may be forfeited and an alternate winner selected.</li>
			</ol>
			<p>The receipt by winner of the prize offered in this&nbsp;Game of Skill Promotion&nbsp;is conditioned upon compliance with any and all federal and state laws and regulations. ANY VIOLATION OF THESE OFFICIAL RULES BY THE WINNER (AT SPONSOR&#x27;S SOLE DISCRETION) WILL RESULT IN SUCH WINNER&#x27;S DISQUALIFICATION AS WINNER OF THE&nbsp;GAME OF SKILL PROMOTION&nbsp;AND ALL PRIVILEGES AS WINNER WILL BE IMMEDIATELY TERMINATED.</p>
			<ol start={7} type="1">
				<li><strong>Rights Granted by You:</strong>&nbsp;By entering this content you understand that Extra, anyone acting on behalf of Extra, or its respective licensees, successors and assigns will have the right, where permitted by law, without any further notice, review or consent to print, publish, broadcast, distribute, and use, worldwide in any media now known or hereafter in perpetuity and throughout the World, your entry, including, without limitation, the entry and winner&#x27;s name, portrait, picture, voice, likeness, image or statements about the&nbsp;Game of Skill Promotion, and biographical information as news, publicity or information and for trade, advertising, public relations and promotional purposes without any further compensation.</li>
			</ol>
			<ol start={8} type="1">
				<li><strong>Terms:</strong>&nbsp;Extra reserves the right, in its sole discretion to cancel, terminate, modify or suspend the&nbsp;Game of Skill Promotion&nbsp;should (in its sole discretion) a virus, bugs, non-authorized human intervention, fraud or other causes beyond its control corrupt or affect the administration, security, fairness or proper conduct of the&nbsp;Game of Skill Promotion. In such a case, Extra may select the recipient from all eligible entries received prior to and/or after (if appropriate) the action taken by Extra. Extra reserves the right at its sole discretion to disqualify any individual who tampers or attempts to tamper with the entry process or the operation of the&nbsp;Game of Skill Promotion&nbsp;or website or violates these Terms &amp; Conditions.</li>
			</ol>
			<p>Extra has the right, in its sole discretion, to maintain the integrity of the&nbsp;Game of Skill Promotion, to void votes for any reason, including, but not limited to; multiple entries from the same user from different IP addresses; multiple entries from the same computer in excess of that allowed by&nbsp;contest&nbsp;rules; or the use of bots, macros or scripts or other technical means for entering. Any attempt by an entrant to deliberately damage any web site or undermine the legitimate operation of the&nbsp;Game of Skill Promotion&nbsp;may be a violation of criminal and civil laws and should such an attempt be made, Extra reserves the right to seek damages from any such person to the fullest extent permitted by law. By entering the&nbsp;Game of Skill Promotion&nbsp;you agree to receive email newsletters periodically from Extra. You can opt-out of receiving this communication at any time by clicking the unsubscribe link in the newsletter.</p>
			<ol start={9} type="1">
				<li><strong>Limitation of Liability:</strong>&nbsp;By entering you agree to release and hold harmless Extra and its subsidiaries, affiliates, advertising and promotion agencies, partners, representatives, agents, successors, assigns, employees, officers and directors from any liability, illness, injury, death, loss, litigation, claim or damage that may occur, directly or indirectly, whether caused by negligence or not, from (i) such entrant&#x27;s participation in the&nbsp;Game of Skill Promotion&nbsp;and/or his/her acceptance, possession, use, or misuse of any prize or any portion thereof, (ii) technical failures of any kind, including but not limited to the malfunctioning of any computer, cable, network, hardware or software; (iii) the unavailability or inaccessibility of any transmissions or telephone or Internet service; (iv) unauthorized human intervention in any part of the entry process or the Promotion; (v) electronic or human error which may occur in the administration of the Promotion or the processing of entries.</li>
			</ol>
			<ol start={1} type="1">
				<li><strong>Disputes:</strong>&nbsp;THIS&nbsp;GAME OF SKILL PROMOTION&nbsp;IS GOVERNED BY THE LAWS OF UNITED STATES OF AMERICA AND CALIFORNIA, WITHOUT RESPECT TO CONFLICT OF LAW DOCTRINES. As a condition of participating in this&nbsp;Game of Skill Promotion, participant agrees that any and all disputes which cannot be resolved between the parties, and causes of action arising out of or connected with this&nbsp;Game of Skill Promotion, shall be resolved individually, without resort to any form of class action, exclusively before a court located in California having jurisdiction. Further, in any such dispute, under no circumstances will participant be permitted to obtain awards for, and hereby waives all rights to claim punitive, incidental, or consequential damages, including reasonable attorneys&#x27; fees, other than participant&#x27;s actual out-of-pocket expenses (i.e. costs associated with entering this&nbsp;Game of Skill Promotion), and participant further waives all rights to have damages multiplied or increased.</li>
			</ol>
			<ol start={1} type="1">
				<li>
					<strong>Privacy Policy</strong>: Information submitted with an entry is subject to the Privacy Policy stated on the Extra Web Site. To read the Privacy Policy, click <a href="https://s3.extra.app/legal/Thingy%20Thing%20Inc%20Privacy%20Policy_April%202020.pdf">here</a>.
				</li>
			</ol>
			<ol start={1} type="1">
				<li><strong>Winners List:</strong>&nbsp;To obtain a copy of the&nbsp;winner&#x27;s&nbsp;information (i.e. initials&nbsp;and state of residence)&nbsp;or a copy of these Official Rules, mail your request along with a stamped, self-addressed envelope to: Extra 360 E 2nd St, Suite 809, Los Angeles, CA 90012, USA. Requests must be received no later than&nbsp;February 13, 2022.</li>
			</ol>
			<ol start={1} type="1">
				<li><strong>Sponsor</strong>: The Sponsor of the&nbsp;Game of Skill Promotion is Extra 360 E 2nd St, Suite 809, Los Angeles, CA 90012, USA.</li>
			</ol>
			<p></p>
		</div>
	)
}

const styles: { [key: string]: CSSProperties } = {
	wrapper: {
		color: 'white',
		fontFamily: 'sans-serif',
		height: 'calc(100vh - 60px - 60px - 30px)',
		overflowY: 'scroll'
	}
}
