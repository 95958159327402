import * as THREE from 'three'
import { UniformsUtils, UniformsLib, MeshBasicMaterialParameters } from 'three'

export default function createGridMaterial({ color, side, opacity, transparent }: MeshBasicMaterialParameters) {
  const additionalUniforms: { [uniform: string]: THREE.IUniform<any> } = {}

  return new THREE.ShaderMaterial({
		...(side && { side }),
		...(opacity && { opacity }),
		...(transparent && { transparent }),
		uniforms: {
			...UniformsUtils.merge([
				UniformsLib.common,
				UniformsLib.specularmap,
				UniformsLib.envmap,
				UniformsLib.aomap,
				UniformsLib.lightmap,
				UniformsLib.fog
			]),
			time: { value: 0 },
			speed: { value: 0 },
			diffuse: { value: new THREE.Color(color as number) },
      ...additionalUniforms
		},
		vertexShader: `
			uniform float speed;
			uniform float time;
			varying vec3 vPos;
			#include <common>
			#include <uv_pars_vertex>
			#include <uv2_pars_vertex>
			#include <envmap_pars_vertex>
			#include <color_pars_vertex>
			#include <fog_pars_vertex>
			#include <morphtarget_pars_vertex>
			#include <skinning_pars_vertex>
			#include <logdepthbuf_pars_vertex>
			#include <clipping_planes_pars_vertex>
			void main() {
				#include <uv_vertex>
				#include <uv2_vertex>
				#include <color_vertex>
				#include <skinbase_vertex>
				#ifdef USE_ENVMAP
				#include <beginnormal_vertex>
				#include <morphnormal_vertex>
				#include <skinnormal_vertex>
				#include <defaultnormal_vertex>
				#endif
				#include <begin_vertex>

				vec2 tuv = uv;
				float t = time * 0.001 * speed;
				vPos = transformed;
				
				#include <morphtarget_vertex>
				#include <skinning_vertex>
				#include <project_vertex>
				#include <logdepthbuf_vertex>
				#include <worldpos_vertex>
				#include <clipping_planes_vertex>
				#include <envmap_vertex>
				#include <fog_vertex>
			}
		`,
		// #extension GL_OES_standard_derivatives : enable
		fragmentShader: `
			uniform float speed;
			uniform float time;
			varying vec3 vPos;

			float line(vec3 position, float width, vec3 step) {
				vec3 tempCoord = position / step;

				vec2 coord = tempCoord.xz;
				coord.y -= time * speed / 2.;

				vec2 grid = abs(fract(coord - 0.5) - 0.5) / fwidth(coord * width);
				float line = min(grid.x, grid.y);

				return min(line, 1.0);
			}
			uniform vec3 diffuse;
			uniform float opacity;
			#ifndef FLAT_SHADED
			varying vec3 vNormal;
			#endif
			#include <common>
			#include <dithering_pars_fragment>
			#include <color_pars_fragment>
			#include <uv_pars_fragment>
			#include <uv2_pars_fragment>
			#include <map_pars_fragment>
			#include <alphamap_pars_fragment>
			#include <aomap_pars_fragment>
			#include <lightmap_pars_fragment>
			#include <envmap_common_pars_fragment>
			#include <envmap_pars_fragment>
			#include <cube_uv_reflection_fragment>
			#include <fog_pars_fragment>
			#include <specularmap_pars_fragment>
			#include <logdepthbuf_pars_fragment>
			#include <clipping_planes_pars_fragment>
			void main() {
				#include <clipping_planes_fragment>
				vec4 diffuseColor = vec4( diffuse, opacity );
				#include <logdepthbuf_fragment>
				#include <map_fragment>
				#include <color_fragment>
				#include <alphamap_fragment>
				#include <alphatest_fragment>
				#include <specularmap_fragment>
				ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
				#ifdef USE_LIGHTMAP

				vec4 lightMapTexel= texture2D( lightMap, vUv2 );
				reflectedLight.indirectDiffuse += lightMapTexelToLinear( lightMapTexel ).rgb * lightMapIntensity;
				#else
				reflectedLight.indirectDiffuse += vec3( 1.0 );
				#endif
				#include <aomap_fragment>
				reflectedLight.indirectDiffuse *= diffuseColor.rgb;
				vec3 outgoingLight = reflectedLight.indirectDiffuse;
				#include <envmap_fragment>

				float l = line(vPos, 1.0, vec3(2.0)); // grid line width
				vec3 base = mix(vec3(0, 0.75, 0), vec3(0), smoothstep(0., 0., abs(vPos.x))); //ROAD COLOR
				vec3 c = mix(outgoingLight, base, l);
				gl_FragColor = vec4(c, diffuseColor.a);

				#include <tonemapping_fragment>
				#include <encodings_fragment>
				#include <fog_fragment>
				#include <premultiplied_alpha_fragment>
				#include <dithering_fragment>
			}
		`,
		extensions: {
			derivatives: true
		}
	})
}
