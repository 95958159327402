import React from 'react'
import * as THREE from 'three'

import { ROAD_LENGTH, ROAD_OFFSET } from '../../utils/constants'
import createGridMaterial from '../../utils/materials'

export default function Floor({ addShader }: { addShader: (shader: THREE.Shader) => void }) {
	const floorGeometry = new THREE.PlaneBufferGeometry(300, ROAD_LENGTH, 1, 1)
	floorGeometry.translate(0, ROAD_OFFSET, 0)
	floorGeometry.rotateX(-Math.PI * 0.5)

	const floorMaterial = createGridMaterial({ color: 0xff1e99 })
	floorMaterial.onBeforeCompile = shader => addShader(shader)

	return (
		<mesh geometry={floorGeometry} material={floorMaterial} />
	)
}
