import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import * as THREE from 'three'

import { useFBX } from '@react-three/drei'
import { easings, animated, useSpring, SpringConfig } from '@react-spring/three'
import { TITLE_ANIMATION_LENGTH } from '../../utils/constants'
import { useListeners } from '../../utils/hooks'

const AnimatedPrimitive = animated.primitive as unknown as any

function DiamondModel({ position, rotation, scale }: any) {
	const fbx = useFBX('/models/Diamond.fbx').clone()
	
	fbx.children.forEach(mesh => {
		if(!(mesh instanceof THREE.Mesh))
			return

		mesh.material = new THREE.MeshNormalMaterial({
			wireframe: true
		})
	})

	return (
		<AnimatedPrimitive
			{...{position, rotation, scale}}

			// wireframe={true}

			object={fbx}
			dispose={null}
		/>
	)
}

const Diamonds = forwardRef(function Diamonds(props, ref) {
	const { rotation } = useSpring({
		from: {
			rotation: [.3, 0, 0]
		},
		to: {
			rotation: [.3, -Math.PI, 0]
		},
		loop: true,
		config: {
			duration: 2000
		}
	})

	const areDiamondsVisible = useRef(true)

	const config: SpringConfig = { duration: 5000, easing: easings.easeInQuad }
	
	const SIZE = 0.0085
	const X_POS = 2
	const Y_POS = 4.82
	const Z_POS = -12

	const [{ position: leftPosition }, setLeftPosition] = useSpring(() => ({ position: [X_POS, Y_POS, Z_POS], config }))
	const [{ position: rightPosition }, setRightPosition] = useSpring(() => ({ position: [-X_POS, Y_POS, Z_POS], config }))

	function showDiamonds(duration = 1000, force = false) {
		if(!force && areDiamondsVisible.current)
			return

		setLeftPosition.set({ position: [X_POS * 5, Y_POS, Z_POS] })
		setRightPosition.set({ position: [-X_POS * 5, Y_POS, Z_POS] })

		setLeftPosition.start({ position: [X_POS, Y_POS, Z_POS], config: { duration, easing: easings.easeOutQuad } })
		setRightPosition.start({ position: [-X_POS, Y_POS, Z_POS], config: { duration, easing: easings.easeOutQuad } })

		areDiamondsVisible.current = true
	}

	function hideDiamonds() {
		if(!areDiamondsVisible.current)
			return

		setLeftPosition.set({ position: [X_POS, Y_POS, Z_POS] })
		setRightPosition.set({ position: [-X_POS, Y_POS, Z_POS] })

		setLeftPosition.start({ position: [X_POS * 100, Y_POS, Z_POS], config: { duration: 5000, easing: easings.easeInQuad } })
		setRightPosition.start({ position: [-X_POS * 100, Y_POS, Z_POS], config: { duration: 5000, easing: easings.easeInQuad } })

		areDiamondsVisible.current = false
	}

	useListeners({
		MATCH_UPDATE: ({ state }) => {
			switch(state) {
			case 'READY':
				hideDiamonds()

				break
			case 'WAITING_FOR_PLAYERS':
				showDiamonds()

				break
			}
		},
		MATCH_LEFT: () => {
			showDiamonds()
		}
	})

	useImperativeHandle(ref, () => ({
		playIntroAnimation() {
			setLeftPosition.set({ position: [0, -10, 0] })
			setRightPosition.set({ position: [0, -10, 0] })

			setTimeout(() => {
				showDiamonds(3000, true)
			}, 4000)
		},
		stopIntroAnimation() {
			showDiamonds(1000, true)
		}
	}))

	return (
		<animated.group>
			<DiamondModel
				position={leftPosition}
				{...{rotation}}
				scale={SIZE}
			/>
			<DiamondModel
				position={rightPosition}
				{...{rotation}}
				scale={SIZE}
			/>
		</animated.group>
	)
})

export default Diamonds
