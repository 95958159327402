import React from 'react'
import * as THREE from 'three'

import { useFBX } from '@react-three/drei'

import { AnimatedPrimitive } from './Car'
import { ROAD_LENGTH } from '../../utils/constants'


const WALL_WIDTH = 4

export default function Wall() {
	function createModel() {
		const fbx = useFBX('/models/Wall.fbx').clone()
	
		// fbx.children.forEach(mesh => {
		// 	if(!(mesh instanceof THREE.Mesh))
		// 		return
	
		// 	mesh.material = new THREE.MeshNormalMaterial({
		// 		wireframe: true
		// 	})
		// })

		return fbx
	}

	return (
		<>
			{new Array(3).fill(null).map((_, i) => (
				<AnimatedPrimitive
					position={[(WALL_WIDTH * i) - WALL_WIDTH, 0, -(500 + 1)]}
					rotation={[0, 0, 0]}

					scale={0.025}

					// wireframe={true}

					object={createModel()}
					dispose={null}

					key={i}
				/>
			))}
		</>
	)
}
