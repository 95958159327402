import React, { useEffect, useMemo, useRef } from 'react'

import * as THREE from 'three'

import { ReactThreeFiber, extend, useFrame, useThree } from '@react-three/fiber'

import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js' // To render post-processing effects
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js' // Bloom/Glow
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js' // Glitch effect
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass.js' // CRT effect
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js' // To merge post-processing effects

extend({ EffectComposer, RenderPass, UnrealBloomPass, GlitchPass, FilmPass })

export default function Shaders({ children }: { children: React.ReactNode }) {
	// return children

	// TO GET EXAMPLES TO WORK
	const { gl, camera, size } = useThree()
	const ref = useRef(null)
	const composer = useRef(null)
  
	const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])

	useFrame(() => ref.current && composer.current.render(), 1)
	useEffect(() => void ref.current && composer.current.setSize(size.width, size.height), [ref, size])

	useEffect(() => {
		ref.current.background = new THREE.CubeTextureLoader().load([
			'/skybox/1024/px.png', // X+
			'/skybox/1024/nx.png', // X-
			'/skybox/1024/py.png', // Y+
			'/skybox/1024/invisible.png', // Y- (out of camera FoV here)
			'/skybox/1024/invisible.png', // Z+ (out of camera FoV here)
			'/skybox/1024/nz.png', // Z-
		])
	}, [ref])

	// CUSTOM
	useEffect(() => {
		gl.toneMapping = THREE.ReinhardToneMapping
		gl.toneMappingExposure = Math.pow(1, 4)
	}, [gl])
  
	// BLOOM
	const bloomRef = useRef<UnrealBloomPass>(null)
  
	useEffect(() => {
		if(!bloomRef.current)
			return

		bloomRef.current.strength = 1.5
		bloomRef.current.threshold = 0
		bloomRef.current.radius = 0.8
	}, [bloomRef.current])

	// GLITCH
	const glitchRef = useRef<GlitchPass>(null)
  
	useEffect(() => {
		glitchRef.current.enabled = false
	}, [glitchRef.current])

	return (
		<>
			<scene ref={ref}>{children}</scene>
			<effectComposer ref={composer} args={[gl]}>
				<renderPass attachArray="passes" scene={ref.current} camera={camera} />

				{/* ACTUAL FX */}
				<unrealBloomPass ref={bloomRef} attachArray="passes" args={[aspect, 1.5, 0, 0.8]} />
				<glitchPass ref={glitchRef} attachArray="passes" args={[1]} />
				<filmPass attachArray="passes" args={[0.2, 0.75, 2048, 0]} />
			</effectComposer>
		</>
	)
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      unrealBloomPass: ReactThreeFiber.Object3DNode<UnrealBloomPass, typeof UnrealBloomPass>
      glitchPass: ReactThreeFiber.Object3DNode<GlitchPass, typeof GlitchPass>
      filmPass: ReactThreeFiber.Object3DNode<FilmPass, typeof FilmPass>
    }
  }
}