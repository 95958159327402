import React, { CSSProperties, forwardRef, Suspense, useEffect, useImperativeHandle, useRef, useState } from 'react'

import * as THREE from 'three'

import { Canvas, useFrame, useThree } from '@react-three/fiber'

import Car from './Car'
import Menu from './Menu'
import Road from './Road'
import Wall from './Wall'
import Walls from './Walls'
import Floor from './Floor'
import Camera from './Camera'
import Scenery from './Scenery'
import Shaders from './Shaders'
import Results from './Results'
import Sidewalk from './Sidewalk'
import Diamonds from './Diamonds'
import Controls from './Controls'
import RoadLines from './RoadLines'
import TrafficLights from './TrafficLights'

import ScoreLabel from './Labels/ScoreLabel'
import GameTitleLabel from './Labels/GameTitleLabel'
import SecondsLeftLabel from './Labels/SecondsLeftLabel'

import useStore from '../../store'
import { ANIMATION_LENGTH } from '../../utils/constants'
import { ULSBooleanTransformer, useListeners, useLocalStorage, useRefWithCallback } from '../../utils/hooks'

const style: CSSProperties = {
	position: 'fixed',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: 'black'
}

const GameScene = forwardRef(function GameScene(props, ref) {
	// 3
	const three = useThree()

	// Shader stuff
	const time = useRef(0)
	const clock = useRef(new THREE.Clock())
	const shaders = useRef<THREE.Shader[]>([])
	const isPlayingAnimation = useRef(false)

	// Players
	const me = useStore(state => state.me)
	const otherPlayer = useStore(state => state.otherPlayer)

	// Scores
	const [scores, setScores] = useState({})

	// Cars
	const myCarRef = useRef(null)
	const otherCarRef = useRef(null)

	// Objects for intro anim
	const titleRef = useRef(null)
	const cameraRef = useRef(null)
	const wallsRef = useRef(null)
	const diamondsRef = useRef(null)

	// Match
	const lastMatchState = useRef(null)

	useEffect(() => {
		three.gl.setSize(window.innerWidth, window.innerHeight)
		three.gl.setPixelRatio(window.devicePixelRatio)
	}, [three])

	useFrame(() => {
		time.current += clock.current.getDelta()

		shaders.current.forEach(shader => {
			shader.uniforms.time.value = time.current
		})
	})

	function addShader(shader: THREE.Shader) {
		shaders.current.push(shader)
	}

	function playIntroAnimation() {
		if(isPlayingAnimation.current)
			return

		// setShaderSpeed(15)
		titleRef.current.playIntroAnimation()
		cameraRef.current.playIntroAnimation()

		wallsRef.current.playIntroAnimation()
		diamondsRef.current.playIntroAnimation()

		isPlayingAnimation.current = true
	}

	function stopIntroAnimation() {
		if(!isPlayingAnimation.current)
			return

		titleRef.current.stopIntroAnimation()
		cameraRef.current.stopIntroAnimation()

		wallsRef.current.stopIntroAnimation()
		diamondsRef.current.stopIntroAnimation()

		isPlayingAnimation.current = false
	}

	function startRound() {
		// setShaderSpeed(15)
		// glitchWallRef.current.startAnimating()
	}

	function stopRound() {
		// setShaderSpeed(0)
		// glitchWallRef.current.stopAnimating()
	}

	function stopMatch() {
		// setShaderSpeed(0)
	}

	const gameServices = useStore(store => store.gameServices)
	useListeners({
		MATCH_UPDATE: ({ state }) => {
			setScores({})

			switch(state) {
			case 'READY':
				// stopIntroAnimation()

				break
			case 'WAITING_FOR_PLAYERS':
				stopMatch()

				break
			}

			lastMatchState.current = state
		},
		PLAYER_LEFT_MATCH: () => {
			if(lastMatchState.current !== 'READY')
				return

			alert('Looks like your opponent chickened out. We\'ve placed you back in the queue 🤞')
		},

		// ROUND STUFF
		ROUND_START: () => {
			startRound()
			// startRampingShadersTo(15, 1000)
		},

		// GAME
		STEAL: () => {
			myCarRef.current.steal(false)
			otherCarRef.current.steal(true)
		},
		TAUNT: () => {
			myCarRef.current.taunt(false)
			otherCarRef.current.taunt(true)
		},
		STALEMATE: () => {
			stopRound()
		},
		SCORE_UPDATE: scores => {
			setScores(scores)
		},

		MATCH_LEFT: () => {
			stopMatch()
		}
	}, gameServices)

	useImperativeHandle(ref, () => ({
		// GAME
		steal() {
			// Stop camera
			cameraRef.current.steal()

			// Play animation
			myCarRef.current.steal(true)
			otherCarRef.current.steal(false)

			gameServices.emit('SEND_MESSAGE', { data: {}, type: 'STEAL' })
		},
		taunt() {
			myCarRef.current.taunt(true)
			otherCarRef.current.taunt(false)
			gameServices.emit('SEND_MESSAGE', { data: {}, type: 'TAUNT' })
		},
		
		// ANIMATION STUFF
		playIntroAnimation,
		stopIntroAnimation,

		// LEADERBOARD / CAMERA MOVEMENT
		showLeaderboard() {
			cameraRef.current.showLeaderboard()
		},
		hideLeaderboard() {
			cameraRef.current.hideLeaderboard()
		}
	}))
	
	return (
		<Shaders>
			<Car ref={myCarRef} user={me} score={scores[me.id]} isLeft={true} />
			<Car ref={otherCarRef} user={otherPlayer} score={scores[otherPlayer?.id]} isLeft={false} />

			<Road />
			<RoadLines />

			<Walls ref={wallsRef} />
			<Floor {...{addShader}} />
			<Sidewalk {...{addShader}} />
			{/* <PalmTrees {...{addShader}} /> */}
			{/* <GroupedPyramids {...{addShader}} /> */}

			<Scenery />
			{/* <GlitchWall /> */}
			<TrafficLights />

			<Diamonds ref={diamondsRef} />

			<Camera ref={cameraRef}>
				<ScoreLabel />
				<SecondsLeftLabel />
			</Camera>

			<Wall />

			{/* <Stats /> */}
			{/* <OrbitControls /> */}

			<GameTitleLabel ref={titleRef} />
		</Shaders>
	)
})

// interface SceneProps {
// 	type: 'game' | 'menu'
// }

export default function Scene() {
	// INTRO STUFF
	const [hasSeenIntroAnimation, setSeenIntroAnimation] = useLocalStorage(false, 'has_seen_intro_animation', ULSBooleanTransformer)

	const hasCheckedIntroAnimation = useRef(false)

	const [sceneRef, setSceneRef] = useRefWithCallback(null, ref => {
		let timeout: NodeJS.Timeout

		if(!hasCheckedIntroAnimation.current && !hasSeenIntroAnimation) {
			ref.current.playIntroAnimation()
			setSeenIntroAnimation(true)

			menuRef.current?.hideMenu()

			console.log('runnin')

			timeout = setTimeout(() => {
				console.log('show menu')
				menuRef.current?.showMenu()
				
				clearTimeout(timeout)
				timeout = null
			}, ANIMATION_LENGTH - 3000)

			hasCheckedIntroAnimation.current = true
		}

		return () => {
			// if(timeout)
			// 	clearTimeout(timeout)
		}
	})

	// UI EVENTS
	const menuRef = useRef(null)
	const resultsRef = useRef(null)

	return (
		<>
			<Canvas style={style}>
				<Suspense fallback>
					<GameScene
						ref={setSceneRef}
						{...{resultsRef}}
					/>
				</Suspense>
			</Canvas>
			<Menu
				ref={menuRef}
				showLeaderboard={() => sceneRef.current.showLeaderboard()}
				hideLeaderboard={() => sceneRef.current.hideLeaderboard()}
			/>
			<Results
				ref={resultsRef}
			/>
			<Controls
				onSteal={() => {
					sceneRef.current.steal()
					resultsRef.current.steal()
				}}

				onTaunt={() => {
					sceneRef.current.taunt()
				}}
			/>
		</>
	)
}
