import React from 'react'
import * as THREE from 'three'

import { useLoader } from '@react-three/fiber'

import { SVGLoader, SVGResult } from 'three/examples/jsm/loaders/SVGLoader.js'

const Y_OFFSET = 10
const Z_OFFSET = 500
const SCALE_OFFSET = 0.08

const SVG_FILES = [
	{ url: './scenery/sun.svg', x: 0, y: 40 + Y_OFFSET, z: -(500 + Z_OFFSET), scale: 0.11 + SCALE_OFFSET, name: 'sun' },
	{ url: './scenery/city_far.svg', x: 0, y: 15 + Y_OFFSET, z: -(450 + Z_OFFSET), scale: 0.4 + SCALE_OFFSET, name: 'cityFar' },
	{ url: './scenery/city_close.svg', x: 0, y: 28 + Y_OFFSET, z: -(300 + Z_OFFSET), scale: 0.2 + SCALE_OFFSET, name: 'cityClose' }
]

interface SceneryItemProps {
	url: string
	name: string

	x: number
	y: number
	z: number
	scale: number
}

function SceneryItem({ url, x, y, z, scale, name }: SceneryItemProps) {
	const group = new THREE.Group()
	const { paths } = useLoader(SVGLoader, url) as SVGResult

	for(const path of paths) {
		const fillColor = path.userData.style.fill
		if(fillColor) {
			const material = new THREE.MeshBasicMaterial({
				color: new THREE.Color().setStyle(fillColor),
				opacity: path.userData.style.fillOpacity,
				transparent: path.userData.style.fillOpacity < 1,
				side: THREE.DoubleSide,
				depthWrite: false
			})

			const svgShapes = path.toShapes(true)

			for(const shape of svgShapes) {
				const geometry = new THREE.ShapeBufferGeometry(shape)
				const mesh = new THREE.Mesh(geometry, material)
      
				group.add(mesh)
			}
		}
	}

	group.position.x = x
	group.position.y = y
	group.position.z = z

	group.scale.y *= -(scale * 0.75)
	group.scale.x *= (scale * 0.75)

	const info = new THREE.Box3().setFromObject(group)
	const size = info.getSize(info.max)

	group.position.x += -size.x + size.x / 2
	group.position.y += size.y / 2

	group.name = name

	return (
		<primitive object={group} dispose={null} />
	)
}

export default function Scenery() {
	const svgComponents = []
	for(const svgFile of SVG_FILES)
		svgComponents.push(<SceneryItem {...svgFile} key={svgFile.name} />)

	return (
		<>
			{svgComponents}
		</>
	)
}
