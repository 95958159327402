import React, { CSSProperties, useState } from 'react'
import { useListeners } from '../../utils/hooks'
import BlurModal from '../BlurModal'
import { Button } from './Menu'

interface ControlsProps {
	onSteal: () => void
	onTaunt: () => void
}

export default function Controls({ onSteal, onTaunt }: ControlsProps) {
	const [visible, setVisible] = useState(false)

	useListeners({
		ROUND_START: () => {
			setVisible(true)
		},
		MATCH_UPDATE: ({ state }) => {
			setVisible(false)
		},
		MATCH_LEFT: () => {
			setVisible(false)
		},
		SCORE_UPDATE: () => {
			setVisible(false)
		},
		STEAL: () => {
			setVisible(false)
		},
		STALEMATE: () => {
			setVisible(false)
		}
	})

	return (
		<BlurModal style={styles.modal} innerStyle={styles.innerModal} show={visible}>
			<Button additionalStyles={{ ...styles.button, marginRight: '10px' }} onClick={onSteal}>
				<p style={styles.label}>Steal</p>
			</Button>
			<Button additionalStyles={styles.button} onClick={onTaunt}>
				<p style={styles.label}>Taunt</p>
			</Button>
		</BlurModal>
	)
}

const styles: { [key: string]: CSSProperties } = {
	modal: {
		top: 'auto',
		height: 'auto',
		bottom: '30px'
	},
	innerModal: {
		display: 'flex',
		flexDirection: 'row',
		padding: '10px'
	},
	button: {
		width: '100%'
	},
	label: {
		color: 'black',
		margin: 0,
		fontSize: '1.5em',
		textAlign: 'center',
		fontFamily: 'Pixel Splitter Bold'  
	}
}
