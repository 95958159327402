import React, { CSSProperties } from 'react'

export default function HowToPlay() {
	return (
		<div style={styles.wrapper}>
			<ol style={styles.list}>
				<li>Each match is comprised of 3 rounds, and lasts 30 seconds each.</li>
				<li>Every second, 25 gems are collected.</li>
				<li>Once the timer hits 25 seconds, 500 gems are collected.</li>
				<li>The first player to steal the gems before the time runs out wins the larger share of the gems collected.</li>
			</ol>

			<h3 style={styles.header}>The Breakdown</h3>
			<ul style={styles.list}>
				<li>If you steal first, you get 70% of the gems collected 🤩</li>
				<li>If you don’t steal, you only get 30% of the gems collected 😥</li>
				<li>If the timer runs out and no one steals, each player only gets 100 gems 😩</li>
				<li>Use your steal button wisely…</li>
			</ul>
		</div>
	)
}

const styles: { [key: string]: CSSProperties } = {
	list: {
		marginTop: 0,
		paddingLeft: '20px'
	},
	header: {
		marginBottom: '0.5em'
	},
	wrapper: {
		color: 'white',
		fontFamily: 'sans-serif',
		height: 'calc(100vh - 60px - 60px - 30px)',
		overflowY: 'scroll'
	}
}
