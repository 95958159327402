import React, { forwardRef, useImperativeHandle, useRef } from 'react'

import { OrbitControls, PerspectiveCamera } from '@react-three/drei'
import { animated, easings, useSpring, SpringConfig } from '@react-spring/three'

import { useListeners } from '../../utils/hooks'
import { ANIMATE_TO, ANIMATION_LENGTH, DEFAULT_CAMERA_POSITIONS, DEFAULT_CAMERA_ROTATIONS, DEV_DISABLE_DRIVE, DRIVING_EASING, LEADERBOARD_ANIMATION_CONFIG } from '../../utils/constants'
import { AnimatedPerspectiveCamera } from './AnimatedPerspectiveCamera'

interface CameraProps {
	children?
}

const Camera = forwardRef(function Camera({ children }: CameraProps, ref) {
	const cameraRef = useRef(null)

	const config: SpringConfig = { duration: ANIMATION_LENGTH, easing: easings.easeOutQuad }

	const [{ position }, setPosition] = useSpring(() => ({ position: DEFAULT_CAMERA_POSITIONS['menu'], config }))
	const [{ rotation }, setRotation] = useSpring(() => ({ rotation: DEFAULT_CAMERA_ROTATIONS['menu'], config }))

	function showDrivingAnimation() {
		if(DEV_DISABLE_DRIVE)
			return

		setPosition.set({ position: DEFAULT_CAMERA_POSITIONS['game'] })
		setRotation.set({ rotation: DEFAULT_CAMERA_ROTATIONS['game'] })

		setPosition.start({ position: DEFAULT_CAMERA_POSITIONS['ending'], config: { duration: 30 * 1000, easing: DRIVING_EASING } })
		setRotation.start({ rotation: DEFAULT_CAMERA_ROTATIONS['ending'] })
	}

	function showCountdownView() {
		setPosition.stop()
		setRotation.stop()

		setPosition.start({ position: DEFAULT_CAMERA_POSITIONS['game'], config: { duration: 1000, easing: easings.easeOutCubic } })
		setRotation.start({ rotation: DEFAULT_CAMERA_ROTATIONS['game'], config: { duration: 1000, easing: easings.easeOutCubic } })
	}

	function showWaitingView() {
		setPosition.stop()
		setRotation.stop()

		setPosition.start({ position: DEFAULT_CAMERA_POSITIONS['menu'], config: { duration: 1000, easing: easings.easeOutCubic } })
		setRotation.start({ rotation: DEFAULT_CAMERA_ROTATIONS['menu'], config: { duration: 1000, easing: easings.easeOutCubic } })
	}

	function stopAnimations() {
		setPosition.stop()
		setRotation.stop()
	}

	function playRoundIntroAnimation() {
		console.log('playRoundIntroAnimation')
		setPosition.set({ position: DEFAULT_CAMERA_POSITIONS['menu'] })
		setRotation.set({ rotation: DEFAULT_CAMERA_ROTATIONS['menu'] })

		setPosition.start({ position: DEFAULT_CAMERA_POSITIONS['game'], config })
		setRotation.start({ rotation: DEFAULT_CAMERA_ROTATIONS['game'], config })
	}

	function stopRoundIntroAnimation() {
		setPosition.set({ position: DEFAULT_CAMERA_POSITIONS['menu'] })
		setRotation.set({ rotation: DEFAULT_CAMERA_ROTATIONS['menu'] })
	}

	useListeners({
		ROUND_START: () => {
			showDrivingAnimation()
		},
		ROUND_READY: () => {
			showCountdownView()
		},
		MATCH_UPDATE: ({ state }) => {
			switch(state) {
			case 'READY':
				stopAnimations()
				playRoundIntroAnimation()

				break
			case 'WAITING_FOR_PLAYERS':
				stopAnimations()
				showWaitingView()
				// stopRoundIntroAnimation()

				break
			}
		},
		STEAL: () => {
			stopAnimations()
		},
		STALEMATE: () => {
			stopAnimations()
		},
		MATCH_LEFT: () => {
			showWaitingView()
		}
	})

	useImperativeHandle(ref, () => ({
		steal() {
			setPosition.stop()
			setRotation.stop()
		},
		// taunt() {

		// },

		playIntroAnimation() {
			console.log('playIntroAnimation')
			setRotation.set({ rotation: [0, 0, .25] })
			setPosition.set({ position: [0, 0.3, 7] })

			setRotation.start({ rotation: DEFAULT_CAMERA_ROTATIONS['menu'], config: { ...config, duration: config.duration / 2 } })
			setPosition.start({ position: DEFAULT_CAMERA_POSITIONS['menu'] })
		},
		stopIntroAnimation() {
			console.log('stopIntroAnimation')
			setRotation.set({ rotation: DEFAULT_CAMERA_ROTATIONS['menu'] })
			setPosition.set({ position: DEFAULT_CAMERA_POSITIONS['menu'] })
		},

		// playRoundIntroAnimation,
		// stopRoundIntroAnimation,

		showLeaderboard() {
			setRotation.start({ rotation: [-(Math.PI / 2), 0, 0], config: LEADERBOARD_ANIMATION_CONFIG })
		},
		hideLeaderboard() {
			setRotation.start({ rotation: DEFAULT_CAMERA_ROTATIONS['menu'], config: LEADERBOARD_ANIMATION_CONFIG })
		}
	}))

	return (
		<animated.group
			position={position as unknown as any}
			rotation={rotation as unknown as any}
		>
			{/* <AnimatedCameraShake
				{...{intensity}}
			/> */}
			{children}
			<AnimatedPerspectiveCamera
				ref={cameraRef}

				fov={75}
				aspect={window.innerWidth / window.innerHeight}
				near={0.1}
				far={2000}
				makeDefault
			/>
			{/* <OrbitControls /> */}
		</animated.group>
	)
})

export default Camera
